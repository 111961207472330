<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper class="text-center" [linear]="true" #stepper>
  <mat-step [stepControl]="consumptionForm.control">
    <ng-template matStepLabel>{{'CONSUMPTION_FORM' | translate}}</ng-template>
    <form #consumptionForm="ngForm">
      <mat-card>
        <div class="row">
          <div translate>UNIT</div>
          <div>
            <mat-form-field>
              <mat-label translate>UNIT</mat-label>
              <mat-select [(ngModel)]="form.unit" name="unit" #unit="ngModel">
                <mat-option [value]="'FRENCH_HARDNESS'">{{'FRENCH_HARDNESS' | translate}}</mat-option>
                <mat-option [value]="'GERMAN_HARDNESS'">{{'GERMAN_HARDNESS' | translate}}</mat-option>
                <mat-option [value]="'MMOL/L'">{{'MMOL/L' | translate}}</mat-option>
                <mat-option [value]="'GRAINS/GALLON'">{{'GRAINS/GALLON' | translate}}</mat-option>
                <mat-option [value]="'CALCIUM_CARBONATE'">{{'CALCIUM_CARBONATE' | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="unit.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>AMOUNT_TO_INCREASE</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input matInput required type="number" [(ngModel)]="form.increase" name="manganese" #manganese="ngModel">
              <span matSuffix>{{unitLabel[form.unit] | translate}}</span>
              <mat-error *ngIf="manganese.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>WATER_VOLUME</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input #waterVolume="ngModel" [(ngModel)]="form.waterVolume" matInput name="waterVolume" required
                     type="number">
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
              <mat-error *ngIf="waterVolume.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>TYPE</div>
          <div>
            <mat-form-field>
              <mat-label translate>TYPE</mat-label>
              <mat-select [(ngModel)]="form.type" name="type" #type="ngModel">
                <mat-option [value]="'LIMESTONE'">{{'LIMESTONE' | translate}}</mat-option>
                <mat-option [value]="'LIMEMILK'">{{'LIMEMILK' | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="type.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'CONSUMPTION_RESULT' | translate}}</ng-template>
    <mat-card class="table-responsive inner mb-5">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th [colSpan]="3" translate>
            CONSUMPTION_RESULT
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="head-col" translate>CO2_NEED</td>
          <td>{{co2need | result:3}} {{'KG_CO2_H' | unit | translate}}</td>
          <td>{{co2need / density | result: 3}} {{'M3' | unit | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" translate>MONTHLY_CONSUMPTION</td>
          <td>{{co2need * 720 | result}} {{'KG_CO2' | unit | translate}}</td>
          <td>{{co2need * 720 / density | result}} {{'M3' | unit | translate}}</td>
        </tr>
        <tr>
          <td class="head-col" translate>YEARLY_CONSUMPTION</td>
          <td>{{co2need * 8640 | result}} {{'KG_CO2' | unit | translate}}</td>
          <td>{{co2need * 8640 / density | result}} {{'M3' | unit | translate}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="table-danger">
          <td [colSpan]="3" [innerHTML]="'ESTIMATION_DISCLAIMER' | translate" translate></td>
        </tr>
        </tfoot>
      </table>
    </mat-card>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'PROJECT_POTENTIAL' | translate}}</ng-template>
    <app-cost-step [weight]="co2need"></app-cost-step>
  </mat-step>
</mat-horizontal-stepper>
