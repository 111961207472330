import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthService, SyncService} from '../shared/service';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {ConfirmAccountComponent} from './confirm-account/confirm-account.component';
import {MatDialog} from '@angular/material/dialog';

import '@codetrix-studio/capacitor-google-auth';
import {Plugins as Capacitor} from '@capacitor/core';

@Component({
  selector   : 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls  : ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  loading            = false;
  appVersion: string = environment.version;
  urlTerms           = 'https://www.caliatys.com/';
  selectedTab        = 0;

  signIn = {username: null, password: null};

  constructor(private matDialog: MatDialog,
              private authService: AuthService,
              public syncService: SyncService) {
  }

  ngOnInit(): void {
  }

  forgotPassword(): void {
    this.matDialog
      .open(ForgotPasswordComponent, {width: '350px', data: this.signIn.username})
      .afterClosed()
      .subscribe(res => res && (this.signIn = res) && this.cognitoSignIn());
  }

  signUp(): void {
    this.matDialog
      .open(SignUpComponent, {width: '350px'})
      .afterClosed()
      .subscribe(res => res && (this.signIn = res) && this.cognitoSignIn());
  }

  googleSignIn(): void {
    this.loading = true;
    Capacitor.GoogleAuth.signIn(null)
      .then((user: any) =>
        user ?
          this.authService.auth.federatedSignIn(
            'google',
            {token: user.authentication.idToken, expires_at: 3600},
            {name: user.name, email: user.email}) :
          Promise.reject()
      )
      .then(() => this.authService.authenticate())
      .catch(console.error)
      .finally(() => this.loading = false);
  }

  cognitoSignIn(): void {
    this.loading = true;
    this.authService.auth.signIn(this.signIn)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return this.confirmAccount();
        }
        return this.authService.authenticate();
      })
      .catch(err => {
        if (err.code === 'UserNotConfirmedException') {
          this.confirmAccount();
        }
      })
      .finally(() => this.loading = false);
  }

  private confirmAccount(): void {
    this.matDialog
      .open(ConfirmAccountComponent, {width: '350px', data: this.signIn.username})
      .afterClosed()
      .subscribe(res => res && this.cognitoSignIn());
  }
}
