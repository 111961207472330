import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {AppService, SyncService} from '../../../shared/service';
import {Company} from '../company-step/company-step-project.component';

export type RemineralizationForm = {
  company: Company
  population: number,
  hardness: {
    value: number,
    unit: 'FRENCH_HARDNESS' | 'GERMAN_HARDNESS' | 'MMOL/L' | 'GRAINS/GALLON' | 'CALCIUM_CARBONATE'
  },
  flowRate: number,
  calcium: number,
  source: {
    limemilk: boolean,
    limestone: boolean,
    sulfate: boolean,
    chloride: boolean,
    blending: boolean,
  }
};

@Component({
  selector   : 'app-remineralization-project',
  templateUrl: './remineralization-project.component.html',
  styleUrls  : ['../project.component.scss', './remineralization-project.component.scss'],
})
export class RemineralizationProjectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('emailContent') emailContent: ElementRef;
  htmlMail: string;

  loading                    = false;
  form: RemineralizationForm = {
    company   : {
      name : '',
      site : '',
      email: ''
    },
    population: 0,
    hardness  : {
      value: 0,
      unit : this.appService.imperial$.value ? 'GRAINS/GALLON' : 'MMOL/L',
    },
    flowRate  : 0,
    calcium   : 0,
    source    : {
      limemilk : false,
      limestone: false,
      sulfate  : false,
      chloride : false,
      blending : false,
    }
  };

  constructor(private appService: AppService,
              private syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('REMINERALIZATION_PROJECT');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/project'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/project'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.htmlMail = this.emailContent.nativeElement.innerHTML, 0);
  }

  onCompanyChanged(company: Company): void {
    this.form.company = company;
  }

  sendMail(): void {
    this.loading = true;
    this.syncService.sendProjectMail('REMINERALIZATION_PROJECT', this.htmlMail)
      .finally(() => this.loading = false);
  }
}
