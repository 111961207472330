import {Component, OnInit} from '@angular/core';
import {AppService, SyncService} from '../../shared/service';

@Component({
  selector   : 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls  : ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private appService: AppService,
    public syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('FAQ');
    this.appService.nav$.next(router => router.navigateByUrl('/home'));
  }
}
