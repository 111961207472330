import {Component, OnInit} from '@angular/core';
import {AppService} from '../../shared/service';

@Component({
  selector   : 'app-setting',
  templateUrl: './settings.component.html',
  styleUrls  : ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('SETTINGS');
    this.appService.nav$.next(router => router.navigateByUrl('/home'));
  }

}
