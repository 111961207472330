import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector   : 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls  : ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string, cancelText: string, confirmText: string },
    public dialogRef: MatDialogRef<ConfirmComponent>) {
    data.title = data.title ?? 'CONFIRM';
    data.cancelText = data.cancelText ?? 'NO';
    data.confirmText = data.confirmText ?? 'YES';
  }

  ngOnInit(): void {
  }
}
