import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../shared/service';

@Component({
  selector   : 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls  : ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  step    = 0;
  loading = false;
  form    = {username: null, code: null, password: null};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public  dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private authService: AuthService,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {
    this.form.username = data;
  }

  ngOnInit(): void {
  }

  reset(): void {
    switch (this.step) {
      case 0 :
        this.resetPassword();
        break;
      case 1 :
        this.confirmReset();
        break;
      case 2 :
        this.dialogRef.close(this.form);
        break;
    }
  }

  private resetPassword(): void {
    this.loading = true;

    this.authService.auth.forgotPassword(this.form.username)
      .then(_ => {
        this.step = 1;
      })
      .catch(err => {
        let errMsg = 'ERROR_RESET_PASSWORD';
        switch (err.code) {
          case 'UserNotFoundException' :
            errMsg = 'ERROR_USER_NOT_FOUND';
            break;
          case 'UserNotConfirmedException' :
            errMsg = 'ERROR_USER_NOT_CONFIRMED';
            break;
          case 'LimitExceededException' :
            errMsg = 'ERROR_USER_NOT_CONFIRMED';
            break;
          case 'InvalidParameterException' :
            errMsg = 'ERROR_INVALID_PARAM';
            break;
        }
        this.snackbar.open(this.translate.instant(errMsg), 'X');
      })
      .finally(() => this.loading = false);
  }

  private confirmReset(): void {
    this.loading = true;
    this.authService.auth.forgotPasswordSubmit(this.form.username, this.form.code, this.form.password)
      .then(_ => {
        this.step = 2;
      })
      .catch(err => {
        let errMsg = 'ERROR_RESET_PASSWORD';
        switch (err.data.code) {
          case 'UserNotFoundException' :
            errMsg = 'ERROR_USER_NOT_FOUND';
            break;
          case 'UserNotConfirmedException' :
            errMsg = 'ERROR_USER_NOT_CONFIRMED';
            break;
          case 'InvalidPasswordException' :
            errMsg = 'ERROR_INVALID_PASSWORD';
            break;
          case 'InvalidParameterException' :
            errMsg = 'ERROR_INVALID_PARAM';
            break;
          case 'LimitExceededException' :
            errMsg = 'ERROR_LIMIT_EXCEED';
            break;
        }
        this.snackbar.open(this.translate.instant(errMsg), 'X');
      })
      .finally(() => this.loading = false);
  }


}
