import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {ConvertPipe, UnitPipe} from '../../../shared/pipe';
import {AppService} from '../../../shared/service';

@Component({
  selector   : 'app-estimation',
  templateUrl: './removal-estimation.component.html',
  styleUrls  : ['../estimation.component.scss', './removal-estimation.component.scss']
})
export class RemovalEstimationComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatHorizontalStepper;

  form = {
    iron       : 0,
    manganese  : 0,
    waterVolume: 0
  };

  constructor(private appService: AppService,
              private convertPipe: ConvertPipe,
              private unitPipe: UnitPipe) {
  }

  get o2need(): number {
    return this.convertPipe.convert(
      this.o2need_kg_h,
      'KG_H',
      this.unitPipe.transform('KG_H')
    );
  }

  get density(): number {
    return this.appService.imperial$.value ? .084527 : 1.354;
  }

  private get o2need_kg_h(): number {
    return (this.form.iron * .149 + this.form.manganese * 0.299)
      / 1000
      * this.convertPipe.convert(this.form.waterVolume, this.unitPipe.transform('M3_H'), 'M3_H');
  }

  ngOnInit(): void {
    this.appService.title$.next('REMOVAL_ESTIMATION');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/estimation'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/estimation'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }
}
