<!--suppress HtmlFormInputWithoutLabel -->
<form #costForm="ngForm">
  <mat-card>
    <div class="row">
      <div translate>PRICE</div>
      <div>
        <mat-form-field>
          <mat-label translate>PRICE</mat-label>
          <input matInput required type="number" [(ngModel)]="form.price" name="price" #price="ngModel">
          <span matSuffix>{{appService.currency$ | async}}</span>
          <mat-error *ngIf="price.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div translate>UNIT</div>
      <div>
        <mat-form-field>
          <mat-label translate>UNIT</mat-label>
          <mat-select #unit="ngModel" [(ngModel)]="form.unit" name="unit">
            <mat-option [value]="'KG'">{{'KG' | unit | translate}}</mat-option>
            <mat-option *ngIf="!(appService.imperial$ | async)" [value]="'T'">{{'T' | unit | translate}}</mat-option>
            <mat-option *ngIf="volume !== null" [value]="'M3'">{{'M3' | unit | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="unit.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
</form>
<mat-card class="table-responsive inner mb-5">
  <table class="table table-bordered">
    <thead>
    <tr>
      <th [colSpan]="2" translate>
        COST_RESULT
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="head-col" translate>ESTIMATED_PRICE</td>
      <td>{{value * form.price | result}} {{this.appService.currency$ | async}} / {{ 'H' | unit | translate }}
        ({{ form.unit | unit | translate}})
      </td>
    </tr>
    <tr>
      <td class="head-col" translate>ESTIMATED_PRICE_MONTHLY</td>
      <td>{{value * form.price * 720 | result}} {{this.appService.currency$ | async}}</td>
    </tr>
    <tr>
      <td class="head-col" translate>ESTIMATED_PRICE_YEARLY</td>
      <td>{{value * form.price * 8640 | result}} {{this.appService.currency$ | async}}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr class="table-danger">
      <td [colSpan]="3" translate [innerHTML]="'ESTIMATION_DISCLAIMER' | translate"></td>
    </tr>
    </tfoot>
  </table>
</mat-card>
