<div class="container py-3">
  <img *ngIf="competitor.image" [src]="competitor.image | fromStorage | async" alt="header">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{'DESCRIPTION' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content [innerText]="competitor.description">
    </mat-card-content>
  </mat-card>
</div>
