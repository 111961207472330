<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper class="text-center" [linear]="true" #stepper>
  <mat-step [stepControl]="consumptionForm.control">
    <ng-template matStepLabel>{{'CONSUMPTION_FORM' | translate}}</ng-template>
    <form #consumptionForm="ngForm">
      <mat-card>
        <div class="disclaimer mb-4" [innerHTML]="'ACID_DISCLAIMER' | translate"></div>
        <div class="row">
          <div translate>REPLACED_MINERAL_ACID</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input matInput required type="number" [(ngModel)]="form.mineral" name="mineral" #mineral="ngModel">
              <span matSuffix>{{'T' | unit | translate}}/{{'MONTH' | translate}}</span>
              <mat-error *ngIf="mineral.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>USED_ACID</div>
          <div>
            <mat-form-field class="align-top">
              <mat-label translate>USED_ACID</mat-label>
              <mat-select [(ngModel)]="form.acid" name="acid" #acid="ngModel">
                <mat-option [value]="'H2SO4'">{{'H2SO4' | translate}}</mat-option>
                <mat-option [value]="'HCl'">{{'HCl' | translate}}</mat-option>
                <mat-option [value]="'HNO3'">{{'HNO3' | translate}}</mat-option>
                <mat-option [value]="'H3PO4'">{{'H3PO4' | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="acid.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <mat-icon class="align-top" (click)="showTooltip = !showTooltip">help_outline</mat-icon>
            <div *ngIf="showTooltip" class="d-inline-block" [innerHTML]="'ACID_TOOLTIP' | translate"></div>
          </div>
        </div>
        <div class="row">
          <div translate>ACID_CONCENTRATION</div>
          <div>
            <mat-form-field>
              <mat-label translate>ACID_CONCENTRATION</mat-label>
              <input matInput required type="number" [(ngModel)]="form.concentration" name="concentration"
                     #concentration="ngModel">
              <span matSuffix>{{'%_WEIGHT' | translate}}</span>
              <mat-error *ngIf="concentration.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'CONSUMPTION_RESULT' | translate}}</ng-template>
    <mat-card class="table-responsive inner mb-5">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th [colSpan]="3" translate>
            CONSUMPTION_RESULT
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="head-col" translate>CO2_NEED</td>
          <td>{{co2need | result}} {{'T' | unit | translate}}/{{'MONTH' | translate}}</td>
          <td></td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" translate>AVERAGE_FLOW</td>
          <td>{{averageFlow | result}} {{'KG_H' | unit | translate}}</td>
          <td>{{averageFlow / density | result}} {{'M3_H' | unit: 'FT3_H' | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" translate>MONTHLY_CONSUMPTION</td>
          <td>{{co2need | result}} {{'T' | unit | translate}}</td>
          <td>{{co2need / density | convert: 'T': 'KG' | result}} {{'M3' | unit | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" translate>YEARLY_CONSUMPTION</td>
          <td>{{co2need * 12 | result}} {{'T' | unit | translate}}</td>
          <td>{{co2need * 12 / density | convert: 'T': 'KG'  | result}} {{'M3' | unit | translate}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="table-danger">
          <td [colSpan]="3" translate [innerHTML]="'ESTIMATION_DISCLAIMER' | translate"></td>
        </tr>
        </tfoot>
      </table>
    </mat-card>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'PROJECT_POTENTIAL' | translate}}</ng-template>
    <app-cost-step [volume]="averageFlow / density" [weight]="averageFlow"></app-cost-step>
  </mat-step>
</mat-horizontal-stepper>
