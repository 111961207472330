<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper class="text-center" [linear]="true" #stepper>
  <mat-step [stepControl]="consumptionForm.control">
    <ng-template matStepLabel>{{'CONSUMPTION_FORM' | translate}}</ng-template>
    <form #consumptionForm="ngForm">
      <mat-card>
        <h4 translate class="mb-5">COMPOUNDS_TO_BE_TREATED</h4>
        <div class="row">
          <div translate>IRON</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input matInput required type="number" [(ngModel)]="form.iron" name="iron" #iron="ngModel">
              <span matSuffix>{{'MG_L' | translate}}</span>
              <mat-error *ngIf="iron.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>MANGANESE</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input matInput required type="number" [(ngModel)]="form.manganese" name="manganese" #manganese="ngModel">
              <span matSuffix>{{'MG_L' | translate}}</span>
              <mat-error *ngIf="manganese.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>WATER_VOLUME</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input #waterVolume="ngModel" [(ngModel)]="form.waterVolume" matInput name="waterVolume" required
                     type="number">
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
              <mat-error *ngIf="waterVolume.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'CONSUMPTION_RESULT' | translate}}</ng-template>
    <mat-card class="table-responsive inner mb-5">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th [colSpan]="3" translate>
            CONSUMPTION_RESULT
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="head-col" translate>O2_NEED</td>
          <td>{{o2need | result:4}} {{'KG_H' | unit | translate}}</td>
          <td></td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" translate>MONTHLY_CONSUMPTION</td>
          <td>{{o2need * 720 | result}} {{'KG' | unit | translate}}</td>
          <td>{{o2need * 720 / density| result}} {{'M3' | unit | translate}}</td>
        </tr>
        <tr>
          <td class="head-col" translate>YEARLY_CONSUMPTION</td>
          <td>{{o2need * 8640 | result}} {{'KG' | unit | translate}}</td>
          <td>{{o2need * 8640 / density | result}} {{'M3' | unit | translate}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="table-danger">
          <td [colSpan]="3" translate [innerHTML]="'ESTIMATION_DISCLAIMER' | translate"></td>
        </tr>
        </tfoot>
      </table>
    </mat-card>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'PROJECT_POTENTIAL' | translate}}</ng-template>
    <app-cost-step [volume]="o2need / density" [weight]="o2need"></app-cost-step>
  </mat-step>
</mat-horizontal-stepper>
