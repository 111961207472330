<div class="container text-center py-3">
  <mat-accordion>
    <mat-expansion-panel hideToggle *ngFor="let faq of (syncService.db$ | async).faq">
      <mat-expansion-panel-header class="header">
        <mat-panel-title>
          {{ faq.question }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="content">{{ faq.answer }}</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
