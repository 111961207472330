<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper #stepper [linear]="true" class="text-center">
  <mat-step>
    <ng-template matStepLabel>{{'COMPANY_FORM' | translate}}</ng-template>
    <app-company-step (company)="onCompanyChanged($event)"></app-company-step>
  </mat-step>
  <mat-step [stepControl]="step1.control">
    <ng-template matStepLabel>{{'PRODUCTION' | translate}}</ng-template>
    <form #step1="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>PRODUCTION</h4>
        <div class="row">
          <div translate>POPULATION_SUPPLIED</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>POPULATION_SUPPLIED</mat-label>
              <input #population="ngModel" [(ngModel)]="form.population" matInput name="population"
                     type="number"/>
              <span matSuffix>{{'HABITANT' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step2.control">
    <ng-template matStepLabel>{{'WATER_CONDITIONS' | translate}}</ng-template>
    <form #step2="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>WATER_CONDITIONS</h4>
        <div class="row">
          <div translate>WATER_FLOW_RATE</div>
          <div>
            <mat-form-field>
              <mat-label translate>WATER_FLOW_RATE</mat-label>
              <input #flowRate="ngModel" [(ngModel)]="form.flowRate" matInput name="flowRate" required type="number">
              <mat-error *ngIf="flowRate.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>TOTAL_IRON</div>
          <div>
            <mat-form-field>
              <mat-label translate>TOTAL_IRON</mat-label>
              <input #iron="ngModel" [(ngModel)]="form.iron" matInput name="iron" type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>MANGANESE</div>
          <div>
            <mat-form-field>
              <mat-label translate>MANGANESE</mat-label>
              <input #manganese="ngModel" [(ngModel)]="form.manganese" matInput name="manganese" type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step3.control">
    <ng-template matStepLabel>{{'TREATMENT_OBJECTIVE' | translate}}</ng-template>
    <form #step3="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>TROUBLE_ENCOUNTERED</h4>
        <div class="row">
          <div translate>HIGH_IRON</div>
          <div>
            <mat-checkbox #highIron="ngModel" [(ngModel)]="form.trouble.highIron"
                          name="highIron" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>HIGH_MANGANESE</div>
          <div>
            <mat-checkbox #highManganese="ngModel" [(ngModel)]="form.trouble.highManganese"
                          name="highManganese" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>ODOR</div>
          <div>
            <mat-checkbox #odor="ngModel" [(ngModel)]="form.trouble.odor"
                          name="odor" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>FILAMENTOUS_BACTERIA</div>
          <div>
            <mat-checkbox #colour="ngModel" [(ngModel)]="form.trouble.colour"
                          name="colour" ngDefaultControl></mat-checkbox>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'SEND_PROJECT' | translate}}</ng-template>
    <mat-card>
      <app-mail-step [htmlMail]="htmlMail"></app-mail-step>
      <mat-card-actions>
        <button (click)="sendMail()" [disabled]="loading" color="primary"
                mat-raised-button>{{ 'SEND'  | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-horizontal-stepper>

<div #emailContent class="d-none">
  <table>
    <thead>
    <tr>
      <th colspan="2">{{ 'COMPANY_INFORMATION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'COMPANY_NAME' | translate }}</th>
      <td>{{form.company.name}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_SITE' | translate }}</th>
      <td>{{form.company.site}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_EMAIL' | translate }}</th>
      <td>{{form.company.email}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'PRODUCTION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'POPULATION_SUPPLIED' | translate }}</th>
      <td>{{form.population}} {{'HABITANT' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'WATER_CONDITIONS' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'WATER_FLOW_RATE' | translate}}</th>
      <td>{{form.flowRate}} {{'M3_H' | unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'TOTAL_IRON' | translate}}</th>
      <td>{{form.iron}} {{'MG_L' | translate}}</td>
    </tr>
    <tr>
      <th>{{'MANGANESE' | translate}}</th>
      <td>{{form.manganese}} {{ 'MG_L' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'TREATMENT_OBJECTIVE' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'TROUBLE_ENCOUNTERED' | translate}}</th>
      <td>
        <div *ngIf="form.trouble.highIron" translate>HIGH_IRON</div>
        <div *ngIf="form.trouble.highManganese" translate>HIGH_MANGANESE</div>
        <div *ngIf="form.trouble.odor" translate>ODOR</div>
        <div *ngIf="form.trouble.colour" translate>FILAMENTOUS_BACTERIA</div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
