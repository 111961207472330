import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {AppService, SyncService} from '../../../shared/service';
import {Company} from '../company-step/company-step-project.component';

export type PhControlForm = {
  company: Company,
  product: string,
  targetPh: number,
  effluentAdjusted: boolean,
  acid: {
    sulfuric: boolean,
    hydrochloric: boolean,
    nitric: boolean,
    phosphoric: boolean,
    other: boolean,
    otherValue: string,
  },
  concentration: number,
  demand: number,
  gradeCost: number,
  flowRate: number,
  temperature: number,
  pH: number,
  capacity: {
    type: 'PK' | 'ALKALINITY',
    pK: {
      a4: number,
      a8: number,
      b8: number
    },
    alkalinity: {
      m: number,
      p: number
    }
  }
};

@Component({
  selector   : 'app-estimation',
  templateUrl: './ph-control-project.component.html',
  styleUrls  : ['../project.component.scss', './ph-control-project.component.scss']
})
export class PhControlProjectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('emailContent') emailContent: ElementRef;
  htmlMail: string;

  loading             = false;
  form: PhControlForm = {
    company         : {
      name : '',
      site : '',
      email: ''
    },
    product         : '',
    targetPh        : 0,
    effluentAdjusted: false,
    acid            : {
      sulfuric    : false,
      hydrochloric: false,
      nitric      : false,
      phosphoric  : false,
      other       : false,
      otherValue  : ''
    },
    concentration   : 0,
    demand          : 0,
    gradeCost       : 0,
    flowRate        : 0,
    temperature     : 0,
    pH              : 0,
    capacity        : {
      type      : 'PK',
      pK        : {
        a4: 0,
        a8: 0,
        b8: 0
      },
      alkalinity: {
        m: 0,
        p: 0
      }
    }
  };

  constructor(public appService: AppService,
              private syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('PH_CONTROL_PROJECT');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/project'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/project'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.htmlMail = this.emailContent.nativeElement.innerHTML, 0);
  }

  onCompanyChanged(company: Company): void {
    this.form.company = company;
  }

  sendMail(): void {
    this.loading = true;
    this.syncService.sendProjectMail('REMINERALIZATION_PROJECT', this.htmlMail)
      .finally(() => this.loading = false);
  }
}
