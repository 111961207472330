import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AppService} from '../../shared/service';

@Component({
  selector   : 'app-information',
  templateUrl: './information.component.html',
  styleUrls  : ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  appVersion: string = environment.version;

  constructor(private appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('INFORMATIONS');
    this.appService.nav$.next(router => router.navigateByUrl('/home'));
  }
}
