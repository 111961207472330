<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper #stepper [linear]="true" class="text-center">
  <mat-step>
    <ng-template matStepLabel>{{'COMPANY_FORM' | translate}}</ng-template>
    <app-company-step (company)="onCompanyChanged($event)"></app-company-step>
  </mat-step>
  <mat-step [stepControl]="step1.control">
    <ng-template matStepLabel>{{'PRODUCTION' | translate}}</ng-template>
    <form #step1="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>PRODUCTION</h4>
        <div class="row">
          <div translate>POPULATION_SUPPLIED</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>POPULATION_SUPPLIED</mat-label>
              <input #population="ngModel" [(ngModel)]="form.population" matInput name="population"
                     type="number"/>
              <span matSuffix>{{'HABITANT' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step2.control">
    <ng-template matStepLabel>{{'WATER_CONDITIONS' | translate}}</ng-template>
    <form #step2="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>WATER_CONDITIONS</h4>

        <div class="row">
          <div translate>WASTEWATER_FLOW_RATE</div>
          <div>
            <mat-form-field>
              <mat-label translate>WASTEWATER_FLOW_RATE</mat-label>
              <input #flowRate="ngModel" [(ngModel)]="form.flowRate" matInput name="flowRate" required type="number">
              <mat-error *ngIf="flowRate.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step3.control">
    <ng-template matStepLabel>{{'TREATMENT_OBJECTIVE' | translate}}</ng-template>
    <form #step3="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>TREATMENT_OBJECTIVE</h4>
        <div class="row">
          <div translate>INSUFFICIENT_SLUDGE_SETTLING</div>
          <div>
            <mat-checkbox #settling="ngModel" [(ngModel)]="form.objective.settling"
                          name="settling" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>SLUDGE_FLOATING</div>
          <div>
            <mat-checkbox #floating="ngModel" [(ngModel)]="form.objective.floating"
                          name="floating" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>BULKING_SLUDGE</div>
          <div>
            <mat-checkbox #bulking="ngModel" [(ngModel)]="form.objective.bulking"
                          name="bulking" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>FOAM_FORMATION</div>
          <div>
            <mat-checkbox #foam="ngModel" [(ngModel)]="form.objective.foam"
                          name="foam" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>FILAMENTOUS_BACTERIA</div>
          <div>
            <mat-checkbox #bacteria="ngModel" [(ngModel)]="form.objective.bacteria"
                          name="bacteria" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>PINPOINT_FLOCKS</div>
          <div>
            <mat-checkbox #flock="ngModel" [(ngModel)]="form.objective.flock"
                          name="flock" ngDefaultControl></mat-checkbox>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step4.control">
    <ng-template matStepLabel>{{'ACTIVATED_SLUDGE' | translate}}</ng-template>
    <form #step4="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>ACTIVATED_SLUDGE</h4>
        <div class="row">
          <div translate>RAS_AMOUNT</div>
          <div>
            <mat-form-field>
              <mat-label translate>RAS_AMOUNT</mat-label>
              <input #ras="ngModel" [(ngModel)]="form.ras" matInput name="ras" required type="number">
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>TSS</div>
          <div>
            <mat-form-field>
              <mat-label translate>TSS</mat-label>
              <input #tss="ngModel" [(ngModel)]="form.tss" matInput name="tss" required type="number">
              <span matSuffix>{{'G_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>EXCESS_SLUDGE</div>
          <div>
            <mat-form-field>
              <mat-label translate>EXCESS_SLUDGE</mat-label>
              <input #excess="ngModel" [(ngModel)]="form.excess" matInput name="excess" required type="number">
              <span matSuffix>{{'M3' | unit | translate}}/{{'DAY' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>DISPOSAL_COST</div>
          <div>
            <mat-form-field>
              <mat-label translate>DISPOSAL_COST</mat-label>
              <input #cost="ngModel" [(ngModel)]="form.cost" matInput name="cost" required type="number">
              <span matSuffix>{{this.appService.currency$ | async}}/{{'T' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'SEND_PROJECT' | translate}}</ng-template>
    <mat-card>
      <app-mail-step [htmlMail]="htmlMail"></app-mail-step>
      <mat-card-actions>
        <button (click)="sendMail()" [disabled]="loading" color="primary"
                mat-raised-button>{{ 'SEND'  | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-horizontal-stepper>


<div #emailContent class="d-none">
  <table>
    <thead>
    <tr>
      <th colspan="2">{{ 'COMPANY_INFORMATION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'COMPANY_NAME' | translate }}</th>
      <td>{{form.company.name}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_SITE' | translate }}</th>
      <td>{{form.company.site}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_EMAIL' | translate }}</th>
      <td>{{form.company.email}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'PRODUCTION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'POPULATION_SUPPLIED' | translate }}</th>
      <td>{{form.population}} {{'HABITANT' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'WATER_CONDITIONS' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'WASTEWATER_FLOW_RATE' | translate }}</th>
      <td>{{form.flowRate}} {{'M3_H' | unit | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'TREATMENT_OBJECTIVE' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="form.objective.settling">
      <th colspan="2">{{'INSUFFICIENT_SLUDGE_SETTLING' | translate}}</th>
    </tr>
    <tr *ngIf="form.objective.floating">
      <th colspan="2">{{'SLUDGE_FLOATING' | translate}}</th>
    </tr>
    <tr *ngIf="form.objective.bulking">
      <th colspan="2">{{'BULKING_SLUDGE' | translate}}</th>
    </tr>
    <tr *ngIf="form.objective.foam">
      <th colspan="2">{{'FOAM_FORMATION' | translate}}</th>
    </tr>
    <tr *ngIf="form.objective.bacteria">
      <th colspan="2">{{'FILAMENTOUS_BACTERIA' | translate}}</th>
    </tr>
    <tr *ngIf="form.objective.flock">
      <th colspan="2">{{'PINPOINT_FLOCKS' | translate}}</th>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'ACTIVATED_SLUDGE' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'RAS_AMOUNT' | translate}}</th>
      <td>{{form.ras}} {{'M3' | unit | translate}}/{{'H' |translate }}</td>
    </tr>
    <tr>
      <th>{{'TSS' | translate}}</th>
      <td>{{form.tss}} {{'G_L' |translate }}</td>
    </tr>
    <tr>
      <th>{{'EXCESS_SLUDGE' | translate}}</th>
      <td>{{form.excess}} {{'M3' | unit | translate}}/{{'DAY' |translate }}</td>
    </tr>
    <tr>
      <th>{{'DISPOSAL_COST' | translate}}</th>
      <td>{{form.cost}} {{this.appService.currency$ | async}}/{{'T' | unit | translate}}</td>
    </tr>
    </tbody>
  </table>
</div>
