import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {AppService, SyncService} from '../../../shared/service';
import {Company} from '../company-step/company-step-project.component';


export type GroundWaterForm = {
  company: Company,
  population: number,
  flowRate: number,
  iron: number,
  manganese: number,
  trouble: {
    highIron: boolean,
    highManganese: boolean,
    odor: boolean,
    colour: boolean,
  }

};

@Component({
  selector   : 'app-estimation',
  templateUrl: './ground-water-project.component.html',
  styleUrls  : ['../project.component.scss', './ground-water-project.component.scss']
})
export class GroundWaterProjectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('emailContent') emailContent: ElementRef;
  htmlMail: string;

  loading               = false;
  form: GroundWaterForm = {
    company   : {
      name : '',
      site : '',
      email: ''
    },
    population: 0,
    flowRate  : 0,
    iron      : 0,
    manganese : 0,
    trouble   : {
      highIron     : false,
      highManganese: false,
      odor         : false,
      colour       : false,
    }
  };

  constructor(private appService: AppService,
              private syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('GROUND_WATER_PROJECT');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/project'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/project'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.htmlMail = this.emailContent.nativeElement.innerHTML, 0);
  }

  onCompanyChanged(company: Company): void {
    this.form.company = company;
  }

  sendMail(): void {
    this.loading = true;
    this.syncService.sendProjectMail('REMINERALIZATION_PROJECT', this.htmlMail)
      .finally(() => this.loading = false);
  }
}
