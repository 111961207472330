import {Component, OnInit} from '@angular/core';
import {AppService, SyncService} from '../../shared/service';

@Component({
  selector   : 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls  : ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  loading = false;
  newVersionName;

  constructor(public syncService: SyncService,
              public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('ADMINISTRATION');
    this.appService.nav$.next(router => router.navigateByUrl('/home'));
  }

  newVersion(): void {
    this.loading = true;
    this.syncService.newVersion(this.newVersionName)
      .catch(console.error)
      .finally(() => this.loading = false);
  }

}
