import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {AppService, SyncService} from '../../../shared/service';
import {Company} from '../company-step/company-step-project.component';

export type SludgeForm = {
  company: Company,
  population: number,
  flowRate: number,
  objective: {
    settling: boolean
    floating: boolean
    bulking: boolean
    foam: boolean
    bacteria: boolean
    flock: boolean
  },
  ras: number,
  tss: number,
  excess: number,
  cost: number,
};

@Component({
  selector   : 'app-sludge-project',
  templateUrl: './sludge-project.component.html',
  styleUrls  : ['../project.component.scss', './sludge-project.component.scss']
})
export class SludgeProjectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('emailContent') emailContent: ElementRef;
  htmlMail: string;

  loading          = false;
  form: SludgeForm = {
    company   : {
      name : '',
      site : '',
      email: ''
    },
    population: 0,
    flowRate  : 0,
    objective : {
      settling: false,
      floating: false,
      bulking : false,
      foam    : false,
      bacteria: false,
      flock   : false,
    },
    ras       : 0,
    tss       : 0,
    excess    : 0,
    cost      : 0,
  };

  constructor(public appService: AppService,
              private syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('SLUDGE_PROJECT');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/project'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/project'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.htmlMail = this.emailContent.nativeElement.innerHTML, 0);
  }

  onCompanyChanged(company: Company): void {
    this.form.company = company;
  }

  sendMail(): void {
    this.loading = true;
    this.syncService.sendProjectMail('REMINERALIZATION_PROJECT', this.htmlMail)
      .finally(() => this.loading = false);
  }
}
