import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Equipment} from '../../shared/model';
import {AppService, SyncService} from '../../shared/service';

@Component({
  selector   : 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls  : ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {
  equipment: Equipment;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    public syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.nav$.next((_, location) => location.back());

    this.route.params.subscribe(params => {
      this.equipment = this.syncService.db$.value.equipments.find(f => f.name === params.name);
      this.appService.title$.next(this.equipment.name);
    });
  }
}
