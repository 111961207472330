<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper #stepper [linear]="true" class="text-center">
  <mat-step>
    <ng-template matStepLabel>{{'COMPANY_FORM' | translate}}</ng-template>
    <app-company-step (company)="onCompanyChanged($event)"></app-company-step>
  </mat-step>
  <mat-step [stepControl]="step1.control">
    <ng-template matStepLabel>{{'ORIGIN_OF_WATER' | translate}}</ng-template>
    <form #step1="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>ORIGIN_OF_WATER</h4>
        <div class="row">
          <div translate>PRODUCT_MANUFACTURED</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>PRODUCT_MANUFACTURED</mat-label>
              <textarea #product="ngModel" [(ngModel)]="form.product" matInput name="product"
                        type="text"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step2.control">
    <ng-template matStepLabel>{{'WATER_CONDITIONS' | translate}}</ng-template>
    <form #step2="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>WATER_CONDITIONS</h4>
        <div class="row">
          <div translate>WATER_FLOW_RATE</div>
          <div>
            <mat-form-field>
              <mat-label translate>WATER_FLOW_RATE</mat-label>
              <input #flowRate="ngModel" [(ngModel)]="form.flowRate" matInput name="flowRate" required type="number">
              <mat-error *ngIf="flowRate.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>COD</div>
          <div>
            <mat-form-field>
              <mat-label translate>COD</mat-label>
              <input #codSent="ngModel" [(ngModel)]="form.cod" matInput name="codSent" type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>
            <mat-form-field>
              <mat-label translate>NITROGEN_OR_AMMONIUM</mat-label>
              <mat-select #nTotalType="ngModel" [(ngModel)]="form.nTotal.type" name="requirementType">
                <mat-option [value]="'INLET_NITROGEN'">{{'INLET_NITROGEN' | translate}}</mat-option>
                <mat-option [value]="'INLET_AMMONIUM'">{{'INLET_AMMONIUM' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label translate>NITROGEN_OR_AMMONIUM</mat-label>
              <input #nTotalValue="ngModel" [(ngModel)]="form.nTotal.value" matInput name="nTotalValue" type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step3.control">
    <ng-template matStepLabel>{{'EXISTING_TREATMENT_PLANT' | translate}}</ng-template>
    <form #step3="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>EXISTING_TREATMENT_PLANT</h4>
        <div class="row">
          <div translate>DISSOLVED_OXYGEN</div>
          <div>
            <mat-form-field>
              <mat-label translate>DISSOLVED_OXYGEN</mat-label>
              <input #dissolved="ngModel" [(ngModel)]="form.dissolved" matInput name="dissolved" type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step4.control">
    <ng-template matStepLabel>{{'TREATMENT_OBJECTIVE' | translate}}</ng-template>
    <form #step4="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>REQUIREMENT_AFTER_TREATMENT</h4>
        <div class="row">
          <div translate>COD</div>
          <div>
            <mat-form-field>
              <mat-label translate>COD</mat-label>
              <input #requirementCodValue="ngModel" [(ngModel)]="form.requirement.cod.value" matInput
                     name="requirementCodValue" type="number">
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>UNIT</mat-label>
              <mat-select #requirementCodUnit="ngModel" [(ngModel)]="form.requirement.cod.unit"
                          name="requirementCodUnit">
                <mat-option [value]="'%_OF_REMOVAL'">{{'%_OF_REMOVAL' | translate}}</mat-option>
                <mat-option [value]="'MG_L'">{{'MG_L' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>TOTAL_NITROGEN</div>
          <div>
            <mat-form-field>
              <mat-label translate>TOTAL_NITROGEN</mat-label>
              <input #requirementNitrogenValue="ngModel" [(ngModel)]="form.requirement.nitrogen.value" matInput
                     name="requirementNitrogenValue" type="number">
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>UNIT</mat-label>
              <mat-select #requirementNitrogenUnit="ngModel" [(ngModel)]="form.requirement.nitrogen.unit"
                          name="requirementNitrogenUnit">
                <mat-option [value]="'%_OF_REMOVAL'">{{'%_OF_REMOVAL' | translate}}</mat-option>
                <mat-option [value]="'MG_L'">{{'MG_L' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <mat-card>
        <h4 class="mb-5" translate>TROUBLE_ENCOUNTERED</h4>
        <div class="row">
          <div translate>OVERLOAD</div>
          <div>
            <mat-checkbox #overload="ngModel" [(ngModel)]="form.trouble.overload"
                          name="overload" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>ODOR</div>
          <div>
            <mat-checkbox #odor="ngModel" [(ngModel)]="form.trouble.odor"
                          name="odor" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>SLUDGE_SETTLING</div>
          <div>
            <mat-checkbox #sludge="ngModel" [(ngModel)]="form.trouble.sludge"
                          name="sludge" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>FOAMING</div>
          <div>
            <mat-checkbox #foaming="ngModel" [(ngModel)]="form.trouble.foaming"
                          name="foaming" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>FILAMENTOUS_BACTERIA</div>
          <div>
            <mat-checkbox #bacteria="ngModel" [(ngModel)]="form.trouble.bacteria"
                          name="bacteria" ngDefaultControl></mat-checkbox>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'SEND_PROJECT' | translate}}</ng-template>
    <mat-card>
      <app-mail-step [htmlMail]="htmlMail"></app-mail-step>
      <mat-card-actions>
        <button (click)="sendMail()" [disabled]="loading" color="primary"
                mat-raised-button>{{ 'SEND'  | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-horizontal-stepper>

<div #emailContent class="d-none">
  <table>
    <thead>
    <tr>
      <th colspan="2">{{ 'COMPANY_INFORMATION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'COMPANY_NAME' | translate }}</th>
      <td>{{form.company.name}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_SITE' | translate }}</th>
      <td>{{form.company.site}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_EMAIL' | translate }}</th>
      <td>{{form.company.email}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'ORIGIN_OF_WATER' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'PRODUCT_MANUFACTURED' | translate }}</th>
      <td>{{form.product}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'WATER_CONDITIONS' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'WATER_FLOW_RATE' | translate}}</th>
      <td>{{form.flowRate}} {{'M3_H' | unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'COD' | translate}}</th>
      <td>{{form.cod}}</td>
    </tr>
    <tr>
      <th>{{'NITROGEN_OR_AMMONIUM' | translate}}</th>
      <td>{{form.nTotal.value}} {{ 'MG_L' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'EXISTING_TREATMENT_PLANT' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'DISSOLVED_OXYGEN' | translate }}</th>
      <td>{{form.dissolved}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'TREATMENT_OBJECTIVE' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'COD' | translate}}</th>
      <td>{{form.requirement.cod.value}} {{form.requirement.cod.unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'TOTAL_NITROGEN' | translate}}</th>
      <td>{{form.requirement.nitrogen.value}} {{form.requirement.nitrogen.unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'TROUBLE_ENCOUNTERED' | translate}}</th>
      <td>
        <div *ngIf="form.trouble.overload" translate>OVERLOAD</div>
        <div *ngIf="form.trouble.odor" translate>ODOR</div>
        <div *ngIf="form.trouble.sludge" translate>SLUDGE_SETTLING</div>
        <div *ngIf="form.trouble.foaming" translate>FOAMING</div>
        <div *ngIf="form.trouble.bacteria" translate>FILAMENTOUS_BACTERIA</div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
