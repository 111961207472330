import {Component, OnInit} from '@angular/core';
import {AppService} from '../../shared/service';

@Component({
  selector   : 'app-home',
  templateUrl: './home.component.html',
  styleUrls  : ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('WATERAPP');
    this.appService.nav$.next(null);
  }
}
