import {Component, OnInit} from '@angular/core';
import {AppService} from '../../shared/service';

@Component({
  selector   : 'app-estimation',
  templateUrl: './estimation.component.html',
  styleUrls  : ['./estimation.component.scss']
})
export class EstimationComponent implements OnInit {

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('CONSUMPTION_ESTIMATION');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/home')
    });
  }
}
