<!--suppress HtmlFormInputWithoutLabel -->
<div class="py-4 px-5 confirm-dialog-wrapper">
  <h2 mat-dialog-title></h2>
  <mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-label translate>UNLOCK</mat-label>
      <input matInput type="password" required (keyup)="check($event?.target)">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-grow-1 text-right">
      <button mat-raised-button color="warn" (click)="this.dialogRef.close(false)" translate>CANCEL</button>
    </div>
  </mat-dialog-actions>
</div>
