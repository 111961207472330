<div class="container py-3">
  <img *ngIf="solution.header" [src]="solution.header | fromStorage | async" alt="header">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{'DESCRIPTION' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content [innerText]="solution.description"></mat-card-content>
  </mat-card>
  <mat-card class="case-study" *ngFor="let study of solution.studies; let idx = index">
    <mat-card-header>
      <mat-card-title>{{'CASE_STUDY' | translate}} #{{idx + 1}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-card-title>{{'CUSTOMER_NEEDS' | translate}}</mat-card-title>
      <p [innerText]="study.needs"></p>
      <mat-card-title>{{'OUR_SOLUTION' | translate}}</mat-card-title>
      <p [innerText]="study.solution"></p>
      <mat-card-title>{{'BENEFITS' | translate}}</mat-card-title>
      <p [innerText]="study.benefits"></p>
    </mat-card-content>
  </mat-card>
  <img *ngIf="solution.footer" [src]="solution.footer | fromStorage | async" alt="footer">
</div>
