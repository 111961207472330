import {Component, OnInit} from '@angular/core';
import {AppService} from '../../shared/service';

@Component({
  selector   : 'app-project',
  templateUrl: './project.component.html',
  styleUrls  : ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('PROJECT_INITIATION');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/home')
    });
  }
}
