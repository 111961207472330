<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper class="text-center" [linear]="true" #stepper>
  <mat-step [stepControl]="consumptionForm.control">
    <ng-template matStepLabel>{{'CONSUMPTION_FORM' | translate}}</ng-template>
    <form #consumptionForm="ngForm">
      <mat-card>
        <h4 translate class="mb-5">QUANTITIES_TREATED</h4>
        <div class="row">
          <div translate>COD</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input matInput required type="number" [(ngModel)]="form.cod" name="cod" #cod="ngModel">
              <mat-error *ngIf="cod.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>UNIT</mat-label>
              <mat-select [(ngModel)]="form.unit" name="unit" #unit="ngModel">
                <mat-option [value]="'KG'">{{'KG' | unit | translate}}</mat-option>
                <mat-option *ngIf="!(appService.imperial$ | async)"
                            [value]="'T'">{{'T' | unit | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="unit.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>TOTAL_NITRO_NH4</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input matInput required type="number" [(ngModel)]="form.nitro" name="nitro"
                     #nitro="ngModel">
              <mat-error *ngIf="nitro.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>UNIT</mat-label>
              <mat-select disabled [value]="form.unit">
                <mat-option [value]="'KG'">{{'KG' | unit | translate}}</mat-option>
                <mat-option [value]="'T'">{{'T' | unit | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>PERIOD_OF_TIME</div>
          <div>
            <mat-form-field>
              <mat-label translate>PERIOD_OF_TIME</mat-label>
              <mat-select [(ngModel)]="form.period" name="period" #period="ngModel">
                <mat-option [value]="'PER_HOUR'">{{'PER_HOUR' | translate}}</mat-option>
                <mat-option [value]="'PER_DAY'">{{'PER_DAY' | translate}}</mat-option>
                <mat-option [value]="'PER_MONTH'">{{'PER_MONTH' | translate}}</mat-option>
                <mat-option [value]="'PER_YEAR'">{{'PER_YEAR' | translate}}</mat-option>
              </mat-select>
              <mat-error *ngIf="period.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'CONSUMPTION_RESULT' | translate}}</ng-template>
    <mat-card class="table-responsive inner mb-5">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th [colSpan]="3" translate>
            CONSUMPTION_RESULT
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="head-col" translate>O2_NEED</td>
          <td>{{o2need | result}} {{'KG_H' | unit | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td></td>
          <td class="head-col" translate>MIN</td>
          <td class="head-col" translate>MAX</td>
        </tr>
        <tr>
          <td class="head-col" rowspan="2" translate>INJECTION_EFFICIENCY</td>
          <td>{{.8 | percent}}</td>
          <td>{{.95 | percent}}</td>
        </tr>
        <tr>
          <td>{{o2need_80 | result}} {{'KG_H' | unit | translate}}</td>
          <td>{{o2need_95 | result}} {{'KG_H' | unit | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" rowspan="2" translate>MONTHLY_CONSUMPTION</td>
          <td>{{o2need_80 * monthPerPeriod[form.period] | result}} {{form.unit | unit | translate}}</td>
          <td>{{o2need_95 * monthPerPeriod[form.period] | result}} {{form.unit | unit | translate}}</td>
        </tr>
        <tr>
          <td>{{o2need_80 * monthPerPeriod[form.period] / density| result}} {{volume[form.unit] | unit | translate}}</td>
          <td>{{o2need_95 * monthPerPeriod[form.period] / density | result}} {{volume[form.unit] | unit | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" rowspan="2" translate>YEARLY_CONSUMPTION</td>
          <td>{{o2need_80 * yearPerPeriod[form.period] | result}} {{form.unit | unit | translate}}</td>
          <td>{{o2need_95 * yearPerPeriod[form.period] | result}} {{form.unit | unit | translate}}</td>
        <tr>
          <td>{{o2need_80 * yearPerPeriod[form.period] / density | result}} {{volume[form.unit] | unit | translate}}</td>
          <td>{{o2need_95 * yearPerPeriod[form.period] / density | result}} {{volume[form.unit] | unit | translate}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="table-danger">
          <td [colSpan]="3" translate [innerHTML]="'ESTIMATION_DISCLAIMER' | translate"></td>
        </tr>
        </tfoot>
      </table>
    </mat-card>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'PROJECT_POTENTIAL' | translate}}</ng-template>
    <app-cost-step [volume]="o2need_80 / density" [weight]="o2need_80"></app-cost-step>
  </mat-step>
</mat-horizontal-stepper>
