import {Component} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AppService, AuthService, SyncService} from './shared/service';

@Component({
  selector   : 'app-root',
  templateUrl: './app.component.html',
  styleUrls  : ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private authService: AuthService,
    private syncService: SyncService,
    private appService: AppService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon('mat_synchronization', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/mat-synchronization.svg'));
    iconRegistry.addSvgIcon('mat_synchronization_pending', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/mat-synchronization-pending.svg'));
    iconRegistry.addSvgIcon('mat_cogs', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/mat-cogs.svg'));
    iconRegistry.addSvgIcon('mat_estimation', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/mat-estimation.svg'));
    iconRegistry.addSvgIcon('mat_portfolio', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/mat-portfolio.svg'));
    iconRegistry.addSvgIcon('mat_selector', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/mat-selector.svg'));
    iconRegistry.addSvgIcon('mat_faq', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/mat-faq.svg'));
  }
}
