const packageJson = require('../../package.json');

export const environment = {
  production         : true,
  version            : packageJson.version,
  checkUpdateInterval: 30 * 1000,
  placeholder        : './assets/img/image-placeholder.prod.png',

  arnLambda          : 'arn:aws:lambda:eu-west-1:461577683414:function:',
  S3Bucket           : 'euw1-pprd-s3-waterapp-database',
  identityPoolId     : 'eu-west-1:5e255819-6044-4faa-ac17-2344b38ccab1',
  region             : 'eu-west-1',
  userPoolId         : 'eu-west-1_6VMgPaQFM',
  userPoolWebClientId: '58573m1itulbc20bv07419tl2c',

  emailLambda             : 'euw1-pprd-lambda-waterapp-ValidateUserEmail',
  manifestLambda          : 'euw1-pprd-lambda-waterapp-GetManifest',
  syncLambda              : 'euw1-pprd-lambda-waterapp-Synchronize',
  sendMailLambda          : 'euw1-pprd-lambda-waterapp-SendMail',
  askUserCreationLambda   : 'euw1-pprd-lambda-waterapp-RequestUserCreation',
  manageUserCreationLambda: 'euw1-pprd-lambda-waterapp-ManageUserCreationRequest',
  slideLambda             : 'euw1-pprd-lambda-waterapp-GenerateSlide',
};
