import {DecimalPipe} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import * as localForage from 'localforage';
import {Observable} from 'rxjs';
import {delay} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Competitor, EquipmentPortfolio, SolutionPortfolio} from './model';
import {AppService, SyncService} from './service';

@Pipe({
  name: 'fromStorage',
})
export class FromStoragePipe implements PipeTransform {

  static cache: { [key: string]: Promise<SafeUrl>; } = {};

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(fileKey: string): Promise<SafeUrl> {
    if (!fileKey) {
      return Promise.resolve(environment.placeholder);
    }

    if (FromStoragePipe.cache[fileKey]) {
      return FromStoragePipe.cache[fileKey];
    }

    const promise                  = localForage.getItem(fileKey)
      .then((data: Blob) => data ?
        this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)) :
        environment.placeholder
      )
      .catch(err => {
        console.error('FromStorage', err);
        return environment.placeholder;
      });
    FromStoragePipe.cache[fileKey] = promise;
    return promise;
  }
}

type Folio = SolutionPortfolio | EquipmentPortfolio | Competitor;

@Pipe({
  name: 'image',
})
export class ImagePipe implements PipeTransform {

  constructor(private syncService: SyncService) {
  }

  transform(step: string | Folio, last: boolean, focusOnLast: boolean): string {
    return (focusOnLast && last)
      ? this.syncService.db$.value.equipments.find(e => e.name === (step as Folio).folio)?.logo
      : this.syncService.db$.value.images[last ? (step as Folio)?.folio : step as string];
  }
}

@Pipe({
  name: 'result',
})
export class ResultPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: any, precision: number = 0): string {
    return new DecimalPipe(this.locale).transform(value, `1.0-${precision}`);
  }
}

@Pipe({
  name: 'delay',
})
export class DelayPipe<T> implements PipeTransform {

  transform(observable: Observable<T>, quantity: number = 0): Observable<T> {
    return observable.pipe(delay(quantity));
  }
}

@Pipe({
  name: 'unit',
})
export class UnitPipe implements PipeTransform {

  units = {
    M       : 'FEET',
    CM      : 'INCHES',
    M2      : 'FT2',
    L       : 'GALLON',
    C       : 'F',
    M3      : 'FT3',
    MM      : 'INCHES',
    M3_H    : 'GPM',
    KG_H    : 'LB_H',
    KG_CO2_H: 'LB_CO2_H',
    KG_CO2  : 'LB_CO2',
    KG      : 'LB',
    T       : 'LB'
  };

  constructor(private appService: AppService) {
  }

  transform(unit: string, forcedImperial: string = null): string {
    return this.appService.imperial$.value ? forcedImperial ?? this.units[unit] ?? unit : unit;
  }
}


@Pipe({
  name: 'convert',
})
export class ConvertPipe implements PipeTransform {

  conversion = {
    T_KG     : 1000,
    LB_KG    : 0.453592,
    KG_H_LB_H: 1 / 0.453592,
    M3_H_GPM : 4.402868,
    GPM_M3_H : 1 / 4.402868,
  };

  constructor(private appService: AppService,
              private unitPipe: UnitPipe) {
  }

  convert = (value: number, from: string, to: string) => this.transform(value, from, to, false);

  transform(value: number, from: string, to: string, translate = true): number {
    if (translate) {
      from = this.unitPipe.transform(from);
      to   = this.unitPipe.transform(to);
    }

    if (from === to) {
      return value;
    }

    const conversion = `${from}_${to}`;
    const rate       = this.conversion[conversion];
    if (!rate) {
      throw new Error(`${conversion} doesn't exist`);
    }
    return value * rate;
  }
}
