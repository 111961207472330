import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {TranslateService} from '@ngx-translate/core';
import {ConvertPipe, UnitPipe} from '../../../shared/pipe';
import {AppService} from '../../../shared/service';

@Component({
  selector   : 'app-estimation',
  templateUrl: './acid-estimation.component.html',
  styleUrls  : ['../estimation.component.scss', './acid-estimation.component.scss']
})
export class AcidEstimationComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatHorizontalStepper;

  showTooltip = false;

  form = {
    mineral      : 0,
    acid         : 'HCl',
    concentration: 98
  };

  acidEquivalentWeight = {
    H2SO4: 49,
    HCl  : 36.5,
    HNO3 : 63,
    H3PO4: 49
  };

  get acidTooltip(): string {
    return [...Array(6).keys()].map(idx => this.translate.instant(`ACID_TOOLTIP_${idx + 1}`)).join('\n');
  }

  get co2need(): number {
    return this.form.mineral * 44 * this.form.concentration / this.acidEquivalentWeight[this.form.acid] / 100;
  }

  constructor(private appService: AppService,
              private translate: TranslateService,
              private convertPipe: ConvertPipe,
              private unitPipe: UnitPipe) {
  }

  get density(): number {
    return this.appService.imperial$.value ? .11683 : 1.8714;
  }

  get averageFlow(): number {
    const inKg = this.convertPipe.convert(this.co2need, this.unitPipe.transform('T'), 'KG');
    return this.convertPipe.convert(inKg * 12 / 365 / 24, 'KG_H', this.unitPipe.transform('KG_H'));
  }

  ngOnInit(): void {
    this.appService.title$.next('ACID_ESTIMATION');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/estimation'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/estimation'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }
}
