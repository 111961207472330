<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper #stepper [linear]="true" class="text-center">
  <mat-step>
    <ng-template matStepLabel>{{'COMPANY_FORM' | translate}}</ng-template>
    <app-company-step (company)="onCompanyChanged($event)"></app-company-step>
  </mat-step>
  <mat-step [stepControl]="step1.control">
    <ng-template matStepLabel>{{'ORIGIN_OF_WATER' | translate}}</ng-template>
    <form #step1="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>ORIGIN_OF_WATER</h4>
        <div class="row">
          <div translate>PRODUCT_MANUFACTURED</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>PRODUCT_MANUFACTURED</mat-label>
              <textarea #product="ngModel" [(ngModel)]="form.product" matInput name="product"
                        type="text"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step2.control">
    <ng-template matStepLabel>{{'WATER_CONDITIONS' | translate}}</ng-template>
    <form #step2="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>WATER_CONDITIONS</h4>
        <div class="row">
          <div translate>WASTEWATER_FLOW_RATE</div>
          <div>
            <mat-form-field>
              <mat-label translate>WASTEWATER_FLOW_RATE</mat-label>
              <input #flowRate="ngModel" [(ngModel)]="form.flowRate" matInput name="flowRate" required type="number">
              <mat-error *ngIf="flowRate.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>COD_TO_TERTIARY</div>
          <div>
            <mat-form-field>
              <mat-label translate>COD_TO_TERTIARY</mat-label>
              <input #codSent="ngModel" [(ngModel)]="form.codSent" matInput name="codSent" type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>POLLUTANT</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>POLLUTANT</mat-label>
              <mat-select #pollutant="ngModel" [(ngModel)]="form.pollutant" name="pollutant">
                <mat-option [value]="'POLLUTANT_MICRO'">{{'POLLUTANT_MICRO' | translate}}</mat-option>
                <mat-option [value]="'POLLUTANT_METALS'">{{'POLLUTANT_METALS' | translate}}</mat-option>
                <mat-option [value]="'POLLUTANT_MEDICAL'">{{'POLLUTANT_MEDICAL' | translate}}</mat-option>
                <mat-option [value]="'POLLUTANT_COSMETIC'">{{'POLLUTANT_COSMETIC' | translate}}</mat-option>
                <mat-option [value]="'POLLUTANT_INSECTICIDE'">{{'POLLUTANT_INSECTICIDE' | translate}}</mat-option>
                <mat-option [value]="'POLLUTANT_CYANIDE'">{{'POLLUTANT_CYANIDE' | translate}}</mat-option>
                <mat-option [value]="'POLLUTANT_OTHER'">{{'POLLUTANT_OTHER' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>DESCRIPTION</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>DESCRIPTION</mat-label>
              <textarea #description="ngModel" [(ngModel)]="form.description" matInput name="description"
                        type="text"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>CONCENTRATION</div>
          <div>
            <mat-form-field>
              <mat-label translate>CONCENTRATION</mat-label>
              <input #concentration="ngModel" [(ngModel)]="form.concentration" matInput name="concentration"
                     type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step3.control">
    <ng-template matStepLabel>{{'TREATMENT_OBJECTIVE' | translate}}</ng-template>
    <form #step3="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>REQUIREMENT_AFTER_TREATMENT</h4>
        <div class="row">
          <div>
            <mat-form-field>
              <mat-label translate>COD_OR_POLLUTANT</mat-label>
              <mat-select #requirementType="ngModel" [(ngModel)]="form.requirement.type" name="requirementType">
                <mat-option [value]="'REQUIREMENT_COD'">{{'REQUIREMENT_COD' | translate}}</mat-option>
                <mat-option [value]="'REQUIREMENT_POLLUTANT'">{{'REQUIREMENT_POLLUTANT' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field>
              <mat-label translate>OBJECTIVE</mat-label>
              <input #requirementValue="ngModel" [(ngModel)]="form.requirement.value" matInput name="requirementValue"
                     type="number">
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>UNIT</mat-label>
              <mat-select #requirementUnit="ngModel" [(ngModel)]="form.requirement.unit" name="requirementUnit">
                <mat-option [value]="'%_OF_REMOVAL'">{{'%_OF_REMOVAL' | translate}}</mat-option>
                <mat-option [value]="'MG_L'">{{'MG_L' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <mat-card>
        <h4 class="mb-5" translate>TROUBLE_ENCOUNTERED</h4>
        <div class="row">
          <div translate>REMOVAL_EFFICIENCY</div>
          <div>
            <mat-checkbox #removalEfficiency="ngModel" [(ngModel)]="form.trouble.removalEfficiency"
                          name="removalEfficiency" ngDefaultControl></mat-checkbox>
          </div>
          <div></div>
        </div>
        <div class="row">
          <div translate>BULKING</div>
          <div>
            <mat-checkbox #bulking="ngModel" [(ngModel)]="form.trouble.bulking"
                          name="bulking" ngDefaultControl></mat-checkbox>
          </div>
          <div></div>
        </div>
        <div class="row">
          <div translate>HIGH_SVI</div>
          <div>
            <mat-checkbox #highSviActive="ngModel" [(ngModel)]="form.trouble.highSvi.active"
                          name="highSviActive" ngDefaultControl></mat-checkbox>
          </div>
          <div>
            <mat-form-field>
              <mat-label translate>OBJECTIVE</mat-label>
              <input #highSviValue="ngModel" [(ngModel)]="form.trouble.highSvi.value"
                     [disabled]="!form.trouble.highSvi.active" matInput name="highSviValue"
                     type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>COLOUR_REMOVAL</div>
          <div>
            <mat-checkbox #colourRemoval="ngModel" [(ngModel)]="form.trouble.colourRemoval"
                          name="colourRemoval" ngDefaultControl></mat-checkbox>
          </div>
          <div></div>
        </div>
        <div class="row">
          <div translate>OVERLOAD</div>
          <div>
            <mat-checkbox #overload="ngModel" [(ngModel)]="form.trouble.overload"
                          name="overload" ngDefaultControl></mat-checkbox>
          </div>
          <div></div>
        </div>
        <div class="row">
          <div translate>ODOR</div>
          <div>
            <mat-checkbox #odor="ngModel" [(ngModel)]="form.trouble.odor"
                          name="odor" ngDefaultControl></mat-checkbox>
          </div>
          <div></div>
        </div>
        <div class="row">
          <div translate>FOAMING</div>
          <div>
            <mat-checkbox #foaming="ngModel" [(ngModel)]="form.trouble.foaming"
                          name="foaming" ngDefaultControl></mat-checkbox>
          </div>
          <div></div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'SEND_PROJECT' | translate}}</ng-template>
    <mat-card>
      <app-mail-step [htmlMail]="htmlMail"></app-mail-step>
      <mat-card-actions>
        <button (click)="sendMail()" [disabled]="loading" color="primary"
                mat-raised-button>{{ 'SEND'  | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-horizontal-stepper>


<div #emailContent class="d-none">
  <table>
    <thead>
    <tr>
      <th colspan="2">{{ 'COMPANY_INFORMATION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'COMPANY_NAME' | translate }}</th>
      <td>{{form.company.name}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_SITE' | translate }}</th>
      <td>{{form.company.site}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_EMAIL' | translate }}</th>
      <td>{{form.company.email}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'ORIGIN_OF_WATER' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'PRODUCT_MANUFACTURED' | translate }}</th>
      <td>{{form.product}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'WATER_CONDITIONS' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'WASTEWATER_FLOW_RATE' | translate}}</th>
      <td>{{form.flowRate}} {{'M3_H' | unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'COD_TO_TERTIARY' | translate}}</th>
      <td>{{form.codSent}} {{'MG_L' | translate}}</td>
    </tr>
    <tr>
      <th>{{'POLLUTANT' | translate}}</th>
      <td>{{form.pollutant | translate}}</td>
    </tr>
    <tr>
      <th>{{'DESCRIPTION' | translate}}</th>
      <td>{{form.description | translate}}</td>
    </tr>
    <tr>
      <th>{{'CONCENTRATION' | translate}}</th>
      <td>{{form.concentration}} {{'MG_L' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'TREATMENT_OBJECTIVE' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ form.requirement.type | translate }}</th>
      <td>{{ form.requirement.value }} {{form.requirement.unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'TROUBLE_ENCOUNTERED' | translate}}</th>
      <td>
        <div *ngIf="form.trouble.removalEfficiency" translate>REMOVAL_EFFICIENCY</div>
        <div *ngIf="form.trouble.bulking" translate>BULKING</div>
        <div *ngIf="form.trouble.highSvi.active">{{ 'HIGH_SVI' | translate }}
          : {{ form.trouble.highSvi.value }} {{'MG_L' | translate}}</div>
        <div *ngIf="form.trouble.colourRemoval" translate>COLOUR_REMOVAL</div>
        <div *ngIf="form.trouble.overload" translate>OVERLOAD</div>
        <div *ngIf="form.trouble.odor" translate>ODOR</div>
        <div *ngIf="form.trouble.foaming" translate>FOAMING</div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
