import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {ConvertPipe, UnitPipe} from '../../../shared/pipe';
import {AppService} from '../../../shared/service';

@Component({
  selector   : 'app-estimation',
  templateUrl: './remineralization-estimation.component.html',
  styleUrls  : ['../estimation.component.scss', './remineralization-estimation.component.scss']
})
export class RemineralizationEstimationComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatHorizontalStepper;

  form = {
    unit       : this.appService.imperial$.value ? 'GRAINS/GALLON' : 'MMOL/L',
    increase   : 0,
    waterVolume: 0,
    type       : 'LIMESTONE'
  };

  unitLabel = {
    FRENCH_HARDNESS  : 'FRENCH_HARDNESS_UNIT',
    GERMAN_HARDNESS  : 'GERMAN_HARDNESS_UNIT',
    'MMOL/L'         : 'MMOL/L_UNIT',
    'GRAINS/GALLON'  : 'GRAINS/GALLON_UNIT',
    CALCIUM_CARBONATE: 'CALCIUM_CARBONATE_UNIT',
  };

  private limeMilkIn  = {
    FRENCH_HARDNESS  : 8.8,
    GERMAN_HARDNESS  : 4.9,
    'MMOL/L'         : 0.88,
    'GRAINS/GALLON'  : 51.4,
    CALCIUM_CARBONATE: 880
  };
  private limeStoneIn = {
    FRENCH_HARDNESS  : 4.4,
    GERMAN_HARDNESS  : 2.5,
    'MMOL/L'         : 0.44,
    'GRAINS/GALLON'  : 25.7,
    CALCIUM_CARBONATE: 440
  };

  constructor(private appService: AppService,
              private unitPipe: UnitPipe,
              private convertPipe: ConvertPipe) {
  }

  get density(): number {
    return this.appService.imperial$.value ? .11683 : 1.8714;
  }

  get co2need(): number {
    return this.convertPipe.convert(
      this.co2need_kg_h,
      'KG_H',
      this.unitPipe.transform('KG_H')
    );
  }

  private get co2need_kg_h(): number {
    const volume = this.convertPipe.convert(this.form.waterVolume, this.unitPipe.transform('M3_H'), 'M3_H');
    switch (this.form.type) {
      case 'LIMESTONE':
        return this.form.increase * this.limeStoneIn[this.form.unit] / 1000 * volume;
      case 'LIMEMILK' :
        return this.form.increase * this.limeMilkIn[this.form.unit] / 1000 * volume;
      default:
        return 0;
    }
  }

  ngOnInit(): void {
    this.appService.title$.next('REMINERALIZATION_ESTIMATION');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/estimation'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/estimation'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }
}
