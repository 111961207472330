<!--suppress HtmlFormInputWithoutLabel -->
<mat-progress-bar mode="indeterminate" *ngIf="loading" class="position-fixed"></mat-progress-bar>
<div class="container py-3 text-center">
  <mat-form-field>
    <mat-label>{{ "CATALOG" | translate }}</mat-label>
    <mat-select [value]="(appService.catalog$ | async)" [compareWith]="appService.comparer" (selectionChange)="appService.catalog$.next($event.value)">
      <mat-option *ngFor="let catalog of (appService.catalogs$ | async)" [value]="catalog">{{catalog.Name}}</mat-option>
    </mat-select>
  </mat-form-field>
    <form *ngIf="appService.catalog$ | async" (ngSubmit)="newVersion()" #newVersionForm="ngForm">
      <div class="text-primary mb-3">
        {{ 'LAST_VERSION' | translate }} : {{ syncService.lastVersion$ | async }}
      </div>
      <div class="row mb-3 justify-content-center">
        <div class="col-12 col-sm-6">
          <mat-form-field class="w-100">
            <mat-label>{{ 'VERSION' | translate }}</mat-label>
            <input matInput type="text" id="version" appForbiddenName="{{syncService.lastVersion$ | async}}" required [(ngModel)]="newVersionName" name="version" #version="ngModel">
            <mat-error *ngIf="version.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            <mat-error *ngIf="version.errors?.forbiddenName">{{ 'FIELD_FORBIDDEN' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="mb-3">
        <button mat-raised-button color="primary" type="submit" [disabled]="loading || !newVersionForm.valid || !(syncService.connected$ | async)">
          {{ 'CREATE_VERSION' | translate }}
        </button>
      </div>
    </form>

</div>
