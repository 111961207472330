import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {ConvertPipe, UnitPipe} from '../../../shared/pipe';
import {AppService} from '../../../shared/service';

@Component({
  selector   : 'app-estimation',
  templateUrl: './o3-estimation.component.html',
  styleUrls  : ['../estimation.component.scss', './o3-estimation.component.scss']
})
export class O3EstimationComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatHorizontalStepper;

  showTooltip = false;

  form = {
    type       : 'HARD_COD',
    codAmount  : 0,
    waterVolume: 0
  };

  private typeToAmount = {
    MICROPOLLUTANT: _ => 7.5,
    DISINFECTION  : _ => 4.0,
    HARD_COD      : _ => 4 * this.form.codAmount
  };

  constructor(private appService: AppService,
              private convertPipe: ConvertPipe,
              private unitPipe: UnitPipe) {
  }

  get o3need(): number {
    return this.convertPipe.convert(
      this.o3need_kg_h,
      'KG_H',
      this.unitPipe.transform('KG_H')
    );
  }

  get coolingWaterNeed(): number {
    return this.convertPipe.convert(
      this.o3need_kg_h * 1.5,
      'M3_H',
      this.unitPipe.transform('M3_H')
    );
  }

  get powerConsumption(): number {
    return this.o3need_kg_h * 8;
  }

  get density(): number {
    return this.appService.imperial$.value ? .084527 : 1.354;
  }

  private get o3need_kg_h(): number {
    return this.typeToAmount[this.form.type]()
      * this.convertPipe.convert(
        this.form.waterVolume,
        this.unitPipe.transform('M3_H'),
        'M3_H'
      )
      / 1000;
  }

  ngOnInit(): void {
    this.appService.title$.next('O3_ESTIMATION');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/estimation'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/estimation'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }
}
