<!--suppress HtmlFormInputWithoutLabel -->
<header class="position-fixed w-100 py-2">
  <img src="./assets/img/air-liquide-creative-oxygen.svg" alt="Logo Air Liquide" id="img-logo" class="img-fluid px-2"/>
</header>
<mat-progress-bar mode="indeterminate" *ngIf="loading" class="position-fixed"></mat-progress-bar>

<mat-tab-group class="hidden-tab" [selectedIndex]="selectedTab">
  <mat-tab label="step-1">
    <div class="sign-in-form-container">
      <div class="sign-in-form">
        <div class="text-center font-weight-bold py-2 mt-2">
          <h3 class="app-name mb-1">{{ 'WATERAPP' | translate | uppercase }}</h3>
        </div>
        <div class="text-center py-2 mt-2">
          <button mat-raised-button type="button" (click)="googleSignIn()" class="cal-btn google">
            <span class="cal-bg-icon">
              <span class="cal-temp-icon"></span>
            </span>
            <span class="cal-label">{{ 'GOOGLE' | translate }}</span>
          </button>
        </div>
        <div class="text-center py-2 mt-2">
          <button mat-raised-button type="button" (click)="selectedTab = 1" class="cal-btn google other">
            <span class="cal-label">{{ 'OTHER_SIGN_IN' | translate }}</span>
          </button>
        </div>
        <div class="text-center py-2 mt-2 rgpd">
          <span innerHtml="{{ 'RGPD_TEXT' | translate : { 'url' : urlTerms, 'class' : 'text-underline' } }}"></span>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="step-2">
    <div class="sign-in-form-container">
      <div class="sign-in-form">
        <div class="text-center font-weight-light">
          {{ 'STD_SIGN_IN_TEXT' | translate }}
        </div>
        <div class="px-3">
          <form (ngSubmit)="cognitoSignIn()" #signInForm="ngForm">
            <mat-form-field class="w-100">
              <mat-label>{{ 'EMAIL' | translate }}</mat-label>
              <input matInput type="email" id="email" required email [(ngModel)]="signIn.username" name="email" #email="ngModel">
              <mat-error *ngIf="email.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
              <mat-error *ngIf="email.errors?.email">{{ 'FIELD_EMAIL' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
              <input matInput type="password" id="password" required [(ngModel)]="signIn.password" name="password" #password="ngModel">
              <mat-error *ngIf="password.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <div class="text-center">
              <button mat-flat-button color="primary" type="submit" [disabled]="!signInForm.form.valid">
                {{ 'SIGN_IN' | translate | uppercase }}
              </button>
            </div>
          </form>
          <div class="row text-muted pt-3">
            <div class="col-7">
              <div class="d-inline-block cursor-pointer">
                <mat-icon class="icon-back align-middle mr-1">arrow_back</mat-icon>
                <span class="align-middle" (click)="selectedTab = 0">{{ 'BACK_TO_HOME' | translate }}</span>
              </div>
            </div>
            <div class="col-5 text-right">
              <div class="d-inline-block cursor-pointer" (click)="forgotPassword()">
                {{ 'FORGOT_PASSWORD' | translate }}
              </div>
            </div>
          </div>
          <div class="row text-muted">
            <div class="col text-center">
              <div class="d-inline-block cursor-pointer" (click)="signUp()">
                {{ 'CREATE_ACCOUNT' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<footer class="position-fixed w-100 bg-primary text-white text-right py-1">
  <div class="py-2 px-4 font-weight-bold">{{ 'VERSION' | translate }} {{ appVersion }}</div>
</footer>
