import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {ConvertPipe, UnitPipe} from '../../../shared/pipe';
import {AppService} from '../../../shared/service';

@Component({
  selector   : 'app-estimation',
  templateUrl: './biological-estimation.component.html',
  styleUrls  : ['../estimation.component.scss', './biological-estimation.component.scss']
})
export class BiologicalEstimationComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper') stepper: MatHorizontalStepper;

  form = {
    cod   : 0,
    nitro : 0,
    unit  : 'KG',
    period: 'PER_HOUR'
  };

  hourPerPeriod = {
    PER_HOUR : 1,
    PER_DAY  : 24,
    PER_MONTH: 24 * 365 / 12,
    PER_YEAR : 24 * 365
  };

  monthPerPeriod = {
    PER_HOUR : 720,
    PER_DAY  : 365 / 12,
    PER_MONTH: 1,
    PER_YEAR : 1 / 12
  };

  yearPerPeriod = {
    PER_HOUR : 8640,
    PER_DAY  : 365,
    PER_MONTH: 12,
    PER_YEAR : 1
  };

  volume = {
    KG: 'M3',
    T : 'M3',
    LB: 'FT3',
  };

  constructor(public appService: AppService,
              private convertPipe: ConvertPipe,
              private unitPipe: UnitPipe) {
  }

  get density(): number {
    return this.appService.imperial$.value ? .084527 : 1.354;
  }

  get o2need_kg_h(): number {
    return this.convertPipe.convert(
      this.form.cod + 4 * this.form.nitro,
      this.unitPipe.transform(this.form.unit),
      'KG'
    ) / this.hourPerPeriod[this.form.period];
  }

  get o2need(): number {
    return this.convertPipe.convert(
      this.o2need_kg_h,
      'KG_H',
      this.unitPipe.transform('KG_H')
    );
  }

  get o2need_80(): number {
    return this.o2need / 0.8;
  }

  get o2need_95(): number {
    return this.o2need / 0.95;
  }

  ngOnInit(): void {
    this.appService.title$.next('BIOLOGICAL_ESTIMATION');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/estimation'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/estimation'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }
}
