<div class="container py-3">
  <ul class="list-unstyled">
    <li class="bg-secondary my-3">
      <a [routerLink]="['/selector']" class="row align-items-center p-3 text-white"
         title="{{ 'SOLUTION_SELECTOR' | translate | uppercase }}">
          <span class="offset-2 col-8 text-center">
            <mat-icon class="align-middle" svgIcon="mat_selector"></mat-icon>
            <span class="d-block text-center mt-2">
              {{ 'SOLUTION_SELECTOR' | translate | uppercase }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a [routerLink]="['/portfolio']" class="row align-items-center p-3 text-white"
         title="{{ 'PORTFOLIO' | translate | uppercase }}">
          <span class="offset-2 col-8 text-center">
            <mat-icon class="align-middle" svgIcon="mat_portfolio"></mat-icon>
            <span class="d-block text-center mt-2">
              {{ 'PORTFOLIO' | translate | uppercase }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li *ngIf="appService.estimation.length !== 0" class="bg-secondary my-3">
      <a [routerLink]="['/estimation']" class="row align-items-center p-3 text-white"
         title="{{ 'CONSUMPTION_ESTIMATION' | translate | uppercase }}">
          <span class="offset-2 col-8 text-center">
            <mat-icon class="align-middle" svgIcon="mat_estimation"></mat-icon>
            <span class="d-block text-center mt-2">
              {{ 'CONSUMPTION_ESTIMATION' | translate | uppercase }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li *ngIf="appService.questionnaire.length !== 0" class="bg-secondary my-3">
      <a [routerLink]="['/project']" class="row align-items-center p-3 text-white"
         title="{{ 'PROJECT_INITIATION' | translate | uppercase }}">
          <span class="offset-2 col-8 text-center">
            <mat-icon class="align-middle" svgIcon="mat_cogs"></mat-icon>
            <span class="d-block text-center mt-2">
              {{ 'PROJECT_INITIATION' | translate | uppercase }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a [routerLink]="['/faq']" class="row align-items-center p-3 text-white"
         title="{{ 'FAQ' | translate | uppercase }}">
          <span class="offset-2 col-8 text-center">
            <mat-icon class="align-middle" svgIcon="mat_faq"></mat-icon>
            <span class="d-block text-center mt-2">
              {{ 'FAQ' | translate | uppercase }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
  </ul>
</div>
