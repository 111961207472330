<div class="container py-3">
  <ul class="list-unstyled">
    <li class="bg-secondary my-3">
      <a *ngIf="appService.questionnaire.includes('tertiary')" [routerLink]="['./tertiary']"
         class="row align-items-center p-3 text-white"
         title="{{ 'TERTIARY_PROJECT' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'TERTIARY_PROJECT' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.questionnaire.includes('sludge')" [routerLink]="['./sludge']"
         class="row align-items-center p-3 text-white"
         title="{{ 'SLUDGE_PROJECT' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'SLUDGE_PROJECT' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.questionnaire.includes('ph-control')" [routerLink]="['./ph-control']"
         class="row align-items-center p-3 text-white"
         title="{{ 'PH_CONTROL_PROJECT' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'PH_CONTROL_PROJECT' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.questionnaire.includes('biological')" [routerLink]="['./biological']"
         class="row align-items-center p-3 text-white"
         title="{{ 'BIOLOGICAL_PROJECT' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'BIOLOGICAL_PROJECT' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.questionnaire.includes('remineralization')" [routerLink]="['./remineralization']"
         class="row align-items-center p-3 text-white"
         title="{{ 'REMINERALIZATION_PROJECT' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'REMINERALIZATION_PROJECT' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.questionnaire.includes('groundwater')" [routerLink]="['./groundwater']"
         class="row align-items-center p-3 text-white"
         title="{{ 'GROUND_WATER_PROJECT' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'GROUND_WATER_PROJECT' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
  </ul>
</div>
