import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector   : 'app-password',
  templateUrl: './password.component.html',
  styleUrls  : ['./password.component.scss']
})
export class PasswordComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) private password: string,
    public dialogRef: MatDialogRef<PasswordComponent>) {
  }

  check(target: any): void {
    if (target.value === this.password) {
      this.dialogRef.close(true);
    }
  }
}
