import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AdministrationComponent} from './navigation/administration/administration.component';
import {CompetitorComponent} from './navigation/competitor/competitor.component';
import {DiscoveryComponent} from './navigation/discovery/discovery.component';
import {EquipmentComponent} from './navigation/equipment/equipment.component';
import {AcidEstimationComponent} from './navigation/estimation/acid/acid-estimation.component';
import {BiologicalEstimationComponent} from './navigation/estimation/biological/biological-estimation.component';
import {CostStepEstimationComponent} from './navigation/estimation/cost-step/cost-step-estimation.component';
import {EstimationComponent} from './navigation/estimation/estimation.component';
import {O3EstimationComponent} from './navigation/estimation/o3/o3-estimation.component';
import {RemineralizationEstimationComponent} from './navigation/estimation/remineralization/remineralization-estimation.component';
import {RemovalEstimationComponent} from './navigation/estimation/removal/removal-estimation.component';
import {FaqComponent} from './navigation/faq/faq.component';
import {HomeComponent} from './navigation/home/home.component';
import {InformationComponent} from './navigation/information/information.component';
import {NavigationComponent} from './navigation/navigation.component';
import {BiologicalProjectComponent} from './navigation/project/biological/biological-project.component';
import {CompanyStepProjectComponent} from './navigation/project/company-step/company-step-project.component';
import {GroundWaterProjectComponent} from './navigation/project/groundwater/ground-water-project.component';
import {MailStepProjectComponent} from './navigation/project/mail-step/mail-step-project.component';
import {PhControlProjectComponent} from './navigation/project/ph-control/ph-control-project.component';
import {ProjectComponent} from './navigation/project/project.component';
import {RemineralizationProjectComponent} from './navigation/project/remineralization/remineralization-project.component';
import {SludgeProjectComponent} from './navigation/project/sludge/sludge-project.component';
import {TertiaryProjectComponent} from './navigation/project/tertiary/tertiary-project.component';
import {SettingsComponent} from './navigation/setting/settings.component';
import {SolutionComponent} from './navigation/solution/solution.component';
import {SynchronizationComponent} from './navigation/synchronization/synchronization.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ConfirmComponent} from './shared/confirm/confirm.component';
import {ForbiddenValidatorDirective} from './shared/directive';
import {PasswordComponent} from './shared/password/password.component';
import {ConvertPipe, DelayPipe, FromStoragePipe, ImagePipe, ResultPipe, UnitPipe} from './shared/pipe';
import {ConfirmAccountComponent} from './sign-in/confirm-account/confirm-account.component';
import {ForgotPasswordComponent} from './sign-in/forgot-password/forgot-password.component';
import {SignInComponent} from './sign-in/sign-in.component';
import {SignUpComponent} from './sign-in/sign-up/sign-up.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ConfirmAccountComponent,
    NavigationComponent,
    HomeComponent,
    SynchronizationComponent,
    SettingsComponent,
    InformationComponent,
    AdministrationComponent,
    ConfirmComponent,
    PasswordComponent,
    DiscoveryComponent,
    SolutionComponent,
    EquipmentComponent,
    CompetitorComponent,
    EstimationComponent,
    AcidEstimationComponent,
    BiologicalEstimationComponent,
    O3EstimationComponent,
    RemineralizationEstimationComponent,
    RemovalEstimationComponent,
    FaqComponent,
    FromStoragePipe,
    DelayPipe,
    ImagePipe,
    ResultPipe,
    UnitPipe,
    ConvertPipe,
    ForbiddenValidatorDirective,
    CostStepEstimationComponent,
    ProjectComponent,
    TertiaryProjectComponent,
    SludgeProjectComponent,
    PhControlProjectComponent,
    BiologicalProjectComponent,
    RemineralizationProjectComponent,
    GroundWaterProjectComponent,
    CompanyStepProjectComponent,
    MailStepProjectComponent,
  ],
  imports     : [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader:
        {
          provide   : TranslateLoader,
          useFactory: http => new AppTranslateLoader(http),
          deps      : [HttpClient]
        }
    }),
    BrowserAnimationsModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatSidenavModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  providers   : [UnitPipe, ConvertPipe],
  bootstrap   : [AppComponent]
})
export class AppModule {
}

class AppTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`./assets/i18n/${lang}.json`)
      .pipe(
        catchError(_ => this.http.get('./assets/i18n/en-US.json')),
        map(remote =>
          Object.assign(
            remote,
            JSON.parse(localStorage.getItem(lang))
          )
        )
      );
  }

}
