<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper #stepper [linear]="true" class="text-center">
  <mat-step>
    <ng-template matStepLabel>{{'COMPANY_FORM' | translate}}</ng-template>
    <app-company-step (company)="onCompanyChanged($event)"></app-company-step>
  </mat-step>
  <mat-step [stepControl]="step1.control">
    <ng-template matStepLabel>{{'PRODUCTION' | translate}}</ng-template>
    <form #step1="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>PRODUCTION</h4>
        <div class="row">
          <div translate>POPULATION_SUPPLIED</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>POPULATION_SUPPLIED</mat-label>
              <input #population="ngModel" [(ngModel)]="form.population" matInput name="population"
                     type="number"/>
              <span matSuffix>{{'HABITANT' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step2.control">
    <ng-template matStepLabel>{{'TREATMENT_OBJECTIVE' | translate}}</ng-template>
    <form #step2="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>REQUIREMENT_AFTER_TREATMENT</h4>
        <div class="row">
          <div translate>HARDNESS_DRINKING</div>
          <div>
            <mat-form-field>
              <mat-label translate>HARDNESS_DRINKING</mat-label>
              <input #hardnessValue="ngModel" [(ngModel)]="form.hardness.value" matInput name="hardnessValue"
                     type="number">
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>UNIT</mat-label>
              <mat-select #hardnessUnit="ngModel" [(ngModel)]="form.hardness.unit"
                          name="hardnessUnit">
                <mat-option [value]="'FRENCH_HARDNESS'">{{'FRENCH_HARDNESS' | translate}}</mat-option>
                <mat-option [value]="'GERMAN_HARDNESS'">{{'GERMAN_HARDNESS' | translate}}</mat-option>
                <mat-option [value]="'MMOL/L'">{{'MMOL/L' | translate}}</mat-option>
                <mat-option [value]="'GRAINS/GALLON'">{{'GRAINS/GALLON' | translate}}</mat-option>
                <mat-option [value]="'CALCIUM_CARBONATE'">{{'CALCIUM_CARBONATE' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>WATER_FLOW_RATE</div>
          <div>
            <mat-form-field>
              <mat-label translate>WATER_FLOW_RATE</mat-label>
              <input #flowRate="ngModel" [(ngModel)]="form.flowRate" matInput name="flowRate" required type="number">
              <mat-error *ngIf="flowRate.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>CALCIUM</div>
          <div>
            <mat-form-field>
              <mat-label translate>CALCIUM</mat-label>
              <input #calcium="ngModel" [(ngModel)]="form.calcium" matInput name="calcium" type="number">
              <span matSuffix>{{'MG_L' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step3.control">
    <ng-template matStepLabel>{{'CALCIUM_SOURCE' | translate}}</ng-template>
    <form #step3="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>CALCIUM_SOURCE</h4>
        <div class="row">
          <div translate>SOURCE_LIMEMILK</div>
          <div>
            <mat-checkbox #limemilk="ngModel" [(ngModel)]="form.source.limemilk"
                          name="limemilk" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>SOURCE_LIMESTONE</div>
          <div>
            <mat-checkbox #limestone="ngModel" [(ngModel)]="form.source.limestone"
                          name="limestone" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>SOURCE_SULFATE</div>
          <div>
            <mat-checkbox #sulfate="ngModel" [(ngModel)]="form.source.sulfate"
                          name="sulfate" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>SOURCE_CHLORIDE</div>
          <div>
            <mat-checkbox #chloride="ngModel" [(ngModel)]="form.source.chloride"
                          name="chloride" ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>SOURCE_BLENDING</div>
          <div>
            <mat-checkbox #blending="ngModel" [(ngModel)]="form.source.blending"
                          name="blending" ngDefaultControl></mat-checkbox>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'SEND_PROJECT' | translate}}</ng-template>
    <mat-card>
      <app-mail-step [htmlMail]="htmlMail"></app-mail-step>
      <mat-card-actions>
        <button (click)="sendMail()" [disabled]="loading" color="primary"
                mat-raised-button>{{ 'SEND'  | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-horizontal-stepper>

<div #emailContent class="d-none">
  <table>
    <thead>
    <tr>
      <th colspan="2">{{ 'COMPANY_INFORMATION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'COMPANY_NAME' | translate }}</th>
      <td>{{form.company.name}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_SITE' | translate }}</th>
      <td>{{form.company.site}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_EMAIL' | translate }}</th>
      <td>{{form.company.email}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'PRODUCTION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'POPULATION_SUPPLIED' | translate }}</th>
      <td>{{form.population}} {{'HABITANT' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'TREATMENT_OBJECTIVE' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'HARDNESS_DRINKING' | translate}}</th>
      <td>{{form.hardness.value}} {{ form.hardness.unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'WATER_FLOW_RATE' | translate}}</th>
      <td>{{form.flowRate}} {{'M3_H' | unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'CALCIUM' | translate}}</th>
      <td>{{form.calcium}} {{'MG_L' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'CALCIUM_SOURCE' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'CALCIUM_SOURCE' | translate}}</th>
      <td>
        <div *ngIf="form.source.limemilk" translate>SOURCE_LIMEMILK</div>
        <div *ngIf="form.source.limestone" translate>SOURCE_LIMESTONE</div>
        <div *ngIf="form.source.sulfate" translate>SOURCE_SULFATE</div>
        <div *ngIf="form.source.chloride" translate>SOURCE_CHLORIDE</div>
        <div *ngIf="form.source.blending" translate>SOURCE_BLENDING</div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
