import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppService, AuthService, SyncService, UserType} from './service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.authenticated$.pipe(
      map(type => type !== UserType.UnAuth || this.router.createUrlTree(['sign-in'], {queryParams: {returnUrl: next.url.toString()}})),
      catchError(() => of(this.router.createUrlTree(['sign-in'])))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class SyncGuard implements CanActivate {

  constructor(private syncService: SyncService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.syncService.lastVersion$.pipe(
      map(version => version === this.syncService.currentVersion$.value || this.router.createUrlTree(['synchronization'])),
      catchError(() => of(this.router.createUrlTree(['synchronization'])))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class ManifestGuard implements CanActivate {

  constructor(private appService: AppService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appService.manifest().then(() => true);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SettingsGuard implements CanActivate {

  constructor(private appService: AppService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appService.settings$.pipe(
      map(([language, catalog, currency]) => (!!language && !!catalog && !!currency) || this.router.createUrlTree(['settings'])),
      catchError(() => of(this.router.createUrlTree(['settings'])))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdvancedGuard implements CanActivate {

  constructor(private appService: AppService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appService.locked$.pipe(
      map(locked => !locked || this.router.createUrlTree(['home'])),
      catchError(() => of(this.router.createUrlTree(['home'])))
    );
  }
}
