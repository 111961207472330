<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper class="text-center" [linear]="true" #stepper>
  <mat-step [stepControl]="consumptionForm.control">
    <ng-template matStepLabel>{{'CONSUMPTION_FORM' | translate}}</ng-template>
    <form #consumptionForm="ngForm">
      <mat-card>
        <div class="row">
          <div translate>PROCESS_OBJECTIVE</div>
          <div>
            <mat-form-field>
              <mat-label translate>PROCESS_OBJECTIVE</mat-label>
              <mat-select #type="ngModel" [(ngModel)]="form.type" name="processObjective">
                <mat-option [value]="'MICROPOLLUTANT'">{{'MICROPOLLUTANT' | translate}}</mat-option>
                <mat-option [value]="'DISINFECTION'">{{'DISINFECTION' | translate}}</mat-option>
                <mat-option [value]="'HARD_COD'">{{'HARD_COD' | translate}}</mat-option>
              </mat-select>
              <!--              <span matSuffix>{{form.type + '_DETAIL' | translate}}</span>-->
              <mat-error *ngIf="type.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="form.type === 'HARD_COD'">
          <div translate>HARD_COD_AMOUNT</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input matInput required type="number" [(ngModel)]="form.codAmount" name="codAmount" #codAmount="ngModel">
              <span matSuffix>{{'MG_L' | translate}}</span>
              <mat-error *ngIf="codAmount.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>WATER_VOLUME</div>
          <div>
            <mat-form-field>
              <mat-label translate>QUANTITY</mat-label>
              <input #waterVolume="ngModel" [(ngModel)]="form.waterVolume" matInput name="waterVolume" required
                     type="number">
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
              <mat-error *ngIf="waterVolume.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'CONSUMPTION_RESULT' | translate}}</ng-template>
    <mat-card class="table-responsive inner mb-5">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th [colSpan]="3" translate>
            CONSUMPTION_RESULT
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="head-col" translate>O2_NEED</td>
          <td>{{o3need * 10 | result:1}} {{'KG_H' | unit | translate}}</td>
          <td>{{o3need * 10 / density | result:1}} {{'M3' | unit | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td class="head-col" translate>MONTHLY_CONSUMPTION</td>
          <td>{{o3need * 10 * 720 | result}} {{'KG' | unit | translate}}</td>
          <td>{{o3need * 10 * 720 / density | result}} {{'M3' | unit | translate}}</td>
        </tr>
        <tr>
          <td class="head-col" translate>YEARLY_CONSUMPTION</td>
          <td>{{o3need * 10 * 8640 | result}} {{'KG' | unit | translate}}</td>
          <td>{{o3need * 10 * 8640 / density | result}} {{'M3' | unit | translate}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td [colSpan]="3" class="table-tooltip">{{'OTHER_UTILITIES' | translate}}
            <mat-icon (click)="showTooltip = !showTooltip">help_outline</mat-icon>
            <div *ngIf="showTooltip" class="tooltip d-inline-block">
              <ul>
                <li>{{'NEEDED_O3' | translate}}  {{o3need | result:2}} {{'KG_H' | unit | translate}}</li>
                <li>{{'NEEDED_N2' | translate}}  {{o3need * .02 | result: 4}} {{'KG_H' | unit | translate}}</li>
                <li>{{'COOLING_WATER' | translate}}  {{coolingWaterNeed | result:2}}  {{'M3_H' | unit | translate}}</li>
                <li>{{'POWER_CONSUMPTION' | translate}}  {{powerConsumption | result:2}}  {{'KWH' | translate}}</li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="table-danger">
          <td [colSpan]="3" [innerHTML]="'ESTIMATION_DISCLAIMER' | translate" translate></td>
        </tr>
        </tfoot>
      </table>
    </mat-card>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'PROJECT_POTENTIAL' | translate}}</ng-template>
    <app-cost-step [volume]="o3need * 10 / density" [weight]="o3need * 10"></app-cost-step>
  </mat-step>
</mat-horizontal-stepper>
