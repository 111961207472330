<!--suppress HtmlFormInputWithoutLabel -->
<mat-horizontal-stepper #stepper [linear]="true" class="text-center">
  <mat-step>
    <ng-template matStepLabel>{{'COMPANY_FORM' | translate}}</ng-template>
    <app-company-step (company)="onCompanyChanged($event)"></app-company-step>
  </mat-step>
  <mat-step [stepControl]="step1.control">
    <ng-template matStepLabel>{{'PRODUCTION' | translate}}</ng-template>
    <form #step1="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>PRODUCTION</h4>
        <div class="row">
          <div translate>PRODUCT_MANUFACTURED</div>
          <div>
            <mat-form-field class="textarea-field">
              <mat-label translate>PRODUCT_MANUFACTURED</mat-label>
              <textarea #product="ngModel" [(ngModel)]="form.product" matInput name="product"
                        type="text"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step2.control">
    <ng-template matStepLabel>{{'EXISTING_TREATMENT_PLANT' | translate}}</ng-template>
    <form #step2="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>NEUTRALIZATION</h4>
        <div class="row">
          <div translate>TARGET_PH</div>
          <div>
            <mat-form-field>
              <mat-label translate>TARGET_PH</mat-label>
              <input #targetPh="ngModel" [(ngModel)]="form.targetPh" matInput name="targetPh" type="number">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>EFFLUENT_ADJUSTED</div>
          <div>
            <mat-checkbox #effluentAdjusted="ngModel" [(ngModel)]="form.effluentAdjusted" name="effluentAdjusted"
                          ngDefaultControl></mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div translate>ACID_APPLIED</div>
          <div class="acid-list">
            <mat-checkbox #acidSulfuric="ngModel" [(ngModel)]="form.acid.sulfuric" name="acidSulfuric"
                          ngDefaultControl>{{'ACID_SULFURIC' | translate}}</mat-checkbox>
            <mat-checkbox #acidHydrochloric="ngModel" [(ngModel)]="form.acid.hydrochloric" name="acidHydrochloric"
                          ngDefaultControl>{{'ACID_HYDROCHLORIC' | translate}}</mat-checkbox>
            <mat-checkbox #acidNitric="ngModel" [(ngModel)]="form.acid.nitric" name="acidNitric"
                          ngDefaultControl>{{'ACID_NITRIC' | translate}}</mat-checkbox>
            <mat-checkbox #acidPhosphoric="ngModel" [(ngModel)]="form.acid.phosphoric" name="acidPhosphoric"
                          ngDefaultControl>{{'ACID_PHOSPHORIC' | translate}}</mat-checkbox>
            <div>
              <mat-checkbox #acidOther="ngModel" [(ngModel)]="form.acid.other" name="acidOther"
                            ngDefaultControl>{{'ACID_OTHER' | translate}}</mat-checkbox>
              <mat-form-field *ngIf="form.acid.other">
                <input #acidOtherValue="ngModel" [(ngModel)]="form.acid.otherValue" matInput name="otherValue"
                       type="text">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card>
        <h4 class="mb-5" translate>EXISTING_PH_CONTROL</h4>
        <div class="row">
          <div translate>ACID_CONCENTRATION</div>
          <div>
            <mat-form-field>
              <mat-label translate>ACID_CONCENTRATION</mat-label>
              <input #concentration="ngModel" [(ngModel)]="form.concentration" matInput name="concentration"
                     type="number">
              <span matSuffix>%</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>ACID_DEMAND</div>
          <div>
            <mat-form-field>
              <mat-label translate>ACID_DEMAND</mat-label>
              <input #demand="ngModel" [(ngModel)]="form.demand" matInput name="demand" type="number">
              <span matSuffix>{{'KG_H' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>GRADE_COST</div>
          <div>
            <mat-form-field>
              <mat-label translate>GRADE_COST</mat-label>
              <input #gradeCost="ngModel" [(ngModel)]="form.gradeCost" matInput name="gradeCost" type="number">
              <span matSuffix>{{this.appService.currency$ | async}}
                /{{'T' | unit | translate}} {{'OF_ACID' | translate}}</span>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step [stepControl]="step3.control">
    <ng-template matStepLabel>{{'WATER_CONDITIONS' | translate}}</ng-template>
    <form #step3="ngForm">
      <mat-card>
        <h4 class="mb-5" translate>WATER_CONDITIONS</h4>
        <div class="row">
          <div translate>WATER_FLOW_RATE</div>
          <div>
            <mat-form-field>
              <mat-label translate>WATER_FLOW_RATE</mat-label>
              <input #flowRate="ngModel" [(ngModel)]="form.flowRate" matInput name="flowRate"
                     required type="number">
              <span matSuffix>{{'M3_H' | unit | translate}}</span>
              <mat-error *ngIf="flowRate.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>TEMPERATURE</div>
          <div>
            <mat-form-field>
              <mat-label translate>TEMPERATURE</mat-label>
              <input #temperature="ngModel" [(ngModel)]="form.temperature" matInput
                     name="temperature" type="number">
              <span matSuffix>{{'C' | unit | translate}}</span>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>PH</div>
          <div>
            <mat-form-field>
              <mat-label translate>PH</mat-label>
              <input #pH="ngModel" [(ngModel)]="form.pH" matInput
                     name="pH" type="number">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>ACID_BASE_CAPACITY</div>
          <div>
            <mat-form-field>
              <mat-label translate>ACID_BASE_CAPACITY</mat-label>
              <mat-select #capacityType="ngModel" [(ngModel)]="form.capacity.type"
                          name="capacityType">
                <mat-option [value]="'PK'">{{'PK' | translate}}</mat-option>
                <mat-option [value]="'ALKALINITY'">{{'ALKALINITY' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="form.capacity.type === 'PK'">
        <div class="row">
          <div translate>PK_A4</div>
          <div>
            <mat-form-field>
              <mat-label translate>PK_A4</mat-label>
              <input #pka4="ngModel" [(ngModel)]="form.capacity.pK.a4" matInput
                     name="pka4" type="number">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>PK_A8</div>
          <div>
            <mat-form-field>
              <mat-label translate>PK_A8</mat-label>
              <input #pkA8="ngModel" [(ngModel)]="form.capacity.pK.a8" matInput
                     name="pkA8" type="number">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>PK_B8</div>
          <div>
            <mat-form-field>
              <mat-label translate>PK_B8</mat-label>
              <input #pkB8="ngModel" [(ngModel)]="form.capacity.pK.b8" matInput
                     name="pkB8" type="number">
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="form.capacity.type === 'ALKALINITY'">
        <div class="row">
          <div translate>ALKALINITY_M</div>
          <div>
            <mat-form-field>
              <mat-label translate>ALKALINITY_M</mat-label>
              <input #alkalinityM="ngModel" [(ngModel)]="form.capacity.alkalinity.m" matInput
                     name="alkalinityM" type="number">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div translate>ALKALINITY_P</div>
          <div>
            <mat-form-field>
              <mat-label translate>ALKALINITY_P</mat-label>
              <input #alkalinityP="ngModel" [(ngModel)]="form.capacity.alkalinity.p" matInput
                     name="alkalinityP" type="number">
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>{{'SEND_PROJECT' | translate}}</ng-template>
    <mat-card>
      <app-mail-step [htmlMail]="htmlMail"></app-mail-step>
      <mat-card-actions>
        <button (click)="sendMail()" [disabled]="loading" color="primary"
                mat-raised-button>{{ 'SEND'  | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-step>
</mat-horizontal-stepper>

<div #emailContent class="d-none">
  <table>
    <thead>
    <tr>
      <th colspan="2">{{ 'COMPANY_INFORMATION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'COMPANY_NAME' | translate }}</th>
      <td>{{form.company.name}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_SITE' | translate }}</th>
      <td>{{form.company.site}}</td>
    </tr>
    <tr>
      <th>{{ 'COMPANY_EMAIL' | translate }}</th>
      <td>{{form.company.email}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'PRODUCTION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'PRODUCT_MANUFACTURED' | translate }}</th>
      <td>{{form.product}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{ 'EXISTING_TREATMENT_PLANT' | translate }}</th>
    </tr>
    <tr class="sub">
      <th colspan="2">{{ 'NEUTRALIZATION' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{ 'TARGET_PH' | translate }}</th>
      <td>{{form.targetPh}}</td>
    </tr>
    <tr>
      <th>{{ 'EFFLUENT_ADJUSTED' | translate }}</th>
      <td>{{form.effluentAdjusted}}</td>
    </tr>
    <tr>
      <th>{{ 'ACID_APPLIED' | translate }}</th>
      <td>
        <div *ngIf="form.acid.sulfuric">{{'ACID_SULFURIC' | translate}}</div>
        <div *ngIf="form.acid.hydrochloric">{{'ACID_HYDROCHLORIC' | translate}}</div>
        <div *ngIf="form.acid.nitric">{{'ACID_NITRIC' | translate}}</div>
        <div *ngIf="form.acid.phosphoric">{{'ACID_PHOSPHORIC' | translate}}</div>
        <div *ngIf="form.acid.other">{{'ACID_OTHER' | translate}}: {{form.acid.otherValue}}</div>
      </td>
    </tr>
    </tbody>
    <thead>
    <tr class="sub">
      <th colspan="2">{{'EXISTING_PH_CONTROL' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'ACID_CONCENTRATION' | translate}}</th>
      <td>{{form.concentration}} %</td>
    </tr>
    <tr>
      <th>{{'ACID_DEMAND' | translate}}</th>
      <td>{{form.demand}} {{'KG_H' | unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'GRADE_COST' | translate}}</th>
      <td>{{form.gradeCost}} {{this.appService.currency$ | async}}
        /{{'T' | unit | translate}}{{'OF_ACID' | translate}}</td>
    </tr>
    </tbody>
    <thead>
    <tr>
      <th colspan="2">{{'WATER_CONDITIONS' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>{{'WATER_FLOW_RATE' | translate}}</th>
      <td>{{form.flowRate}} {{'M3_H' | unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'TEMPERATURE' | translate}}</th>
      <td>{{form.temperature}} {{'C' | unit | translate}}</td>
    </tr>
    <tr>
      <th>{{'PH' | translate}}</th>
      <td>{{form.pH}}</td>
    </tr>
    </tbody>
    <thead>
    <tr class="sub">
      <th colspan="2">{{form.capacity.type | translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="form.capacity.type === 'PK'">
    <tr>
      <th>{{'PK_A4' | translate}}</th>
      <td>{{form.capacity.pK.a4}}</td>
    </tr>
    <tr>
      <th>{{'PK_A8' | translate}}</th>
      <td>{{form.capacity.pK.a8}}</td>
    </tr>
    <tr>
      <th>{{'PK_B8' | translate}}</th>
      <td>{{form.capacity.pK.b8}}</td>
    </tr>
    </tbody>
    <tbody *ngIf="form.capacity.type === 'ALKALINITY'">
    <tr>
      <th>{{'ALKALINITY_M' | translate}}</th>
      <td>{{form.capacity.alkalinity.m}}</td>
    </tr>
    <tr>
      <th>{{'ALKALINITY_P' | translate}}</th>
      <td>{{form.capacity.alkalinity.p}}</td>
    </tr>
    </tbody>
  </table>
</div>
