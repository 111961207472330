import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Solution} from '../../shared/model';
import {AppService, SyncService} from '../../shared/service';

@Component({
  selector   : 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls  : ['./solution.component.scss']
})
export class SolutionComponent implements OnInit {
  solution: Solution;

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.nav$.next((_, location) => location.back());

    this.route.params.subscribe(params => {
      this.solution = this.syncService.db$.value.solutions.find(f => f.name === params.name);
      this.appService.title$.next(this.solution.name);
    });
  }
}
