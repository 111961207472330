import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {Lambda} from '@aws-sdk/client-lambda';
import {S3} from '@aws-sdk/client-s3';
import {TranslateService} from '@ngx-translate/core';
import * as localForage from 'localforage';
import {BehaviorSubject, Subject, timer, zip} from 'rxjs';
import {environment} from '../../environments/environment';
import {Catalog, Database, Language, Manifest, SendMailPayload} from './model';
import {PasswordComponent} from './password/password.component';


export enum UserType {
  UnAuth,
  User,
  Admin
}

type NavCallback = (router: Router, location: Location) => void;

@Injectable({
  providedIn: 'root'
})
export class AppService {

  static readonly LANG_AVAILABLE     = 'WATERAPP_LANG_AVAILABLE';
  static readonly LANG               = 'WATERAPP_LANG';
  static readonly CURRENCY_AVAILABLE = 'WATERAPP_CURRENCY_AVAILABLE';
  static readonly CURRENCY           = 'WATERAPP_CURRENCY';
  static readonly CTLG_AVAILABLE     = 'WATERAPP_CTLG_AVAILABLE';
  static readonly CTLG               = 'WATERAPP_CTLG';
  static readonly LOCKED             = 'WATERAPP_LOCKED';
  static readonly IMPERIAL           = 'WATERAPP_IMPERIAL';

  password                = '1234';
  questionnaire: string[] = [];
  estimation: string[]    = [];

  languages$     = new BehaviorSubject<Language[]>(JSON.parse(localStorage.getItem(AppService.LANG_AVAILABLE)) ?? []);
  language$      = new BehaviorSubject<Language>(JSON.parse(localStorage.getItem(AppService.LANG)));
  currencies$    = new BehaviorSubject<string[]>(JSON.parse(localStorage.getItem(AppService.CURRENCY_AVAILABLE)) ?? []);
  currency$      = new BehaviorSubject<string>(JSON.parse(localStorage.getItem(AppService.CURRENCY)));
  catalogs$      = new BehaviorSubject<Catalog[]>(JSON.parse(localStorage.getItem(AppService.CTLG_AVAILABLE)) ?? []);
  catalog$       = new BehaviorSubject<Catalog>(JSON.parse(localStorage.getItem(AppService.CTLG)));
  imperial$      = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem(AppService.IMPERIAL)) ?? false);
  defaultLocked$ = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem(AppService.LOCKED)) ?? true);
  locked$        = new BehaviorSubject<boolean>(this.defaultLocked$.value);
  title$         = new BehaviorSubject<string>('WATERAPP');
  scrollTop$     = new Subject<void>();
  nav$           = new Subject<NavCallback | { next?: NavCallback, back: NavCallback, alternateBack?: NavCallback }>();

  settings$ = zip(this.language$, this.catalog$, this.currency$, this.imperial$);
  comparer  = (c1: any, c2: any) => c1?.Code === c2?.Code;

  constructor(private translate: TranslateService,
              private matDialog: MatDialog) {
    translate.setDefaultLang('en-US');
    this.languages$.subscribe(languages => {
      localStorage.setItem(AppService.LANG_AVAILABLE, JSON.stringify(languages));
      if (!this.language$.value || !languages.some(c => c.Code === this.language$.value?.Code)) {
        this.language$.next(null);
      }
    });
    this.language$.subscribe(lang => {
      translate.use(lang?.Code ?? 'en-US');
      lang ?
        localStorage.setItem(AppService.LANG, JSON.stringify(lang)) :
        localStorage.removeItem(AppService.LANG);
    });
    this.currencies$.subscribe(currencies => {
      localStorage.setItem(AppService.CURRENCY_AVAILABLE, JSON.stringify(currencies));
      if (!this.currency$.value || !currencies.some(c => c === this.currency$.value)) {
        this.currency$.next(null);
      }
    });
    this.currency$.subscribe(currency => {
      currency ?
        localStorage.setItem(AppService.CURRENCY, JSON.stringify(currency)) :
        localStorage.removeItem(AppService.CURRENCY);
    });
    this.imperial$.subscribe(imperial => {
      imperial ?
        localStorage.setItem(AppService.IMPERIAL, JSON.stringify(true)) :
        localStorage.removeItem(AppService.IMPERIAL);
    });
    this.catalogs$.subscribe(catalogs => {
        localStorage.setItem(AppService.CTLG_AVAILABLE, JSON.stringify(catalogs));
        if (!this.catalog$.value || !catalogs.some(c => c.Code === this.catalog$.value?.Code)) {
          this.catalog$.next(null);
        }
      }
    );
    this.catalog$.subscribe(catalog =>
      catalog ?
        localStorage.setItem(AppService.CTLG, JSON.stringify(catalog)) :
        localStorage.removeItem(AppService.CTLG));
    this.defaultLocked$.subscribe(mode => localStorage.setItem(AppService.LOCKED, JSON.stringify(mode)));
  }

  unlock(): void {
    this.matDialog
      .open(PasswordComponent, {width: '350px', data: this.password})
      .afterClosed()
      .toPromise()
      .then(res =>
        res && this.locked$.next(false)
      );
  }

  manifest(): Promise<void> {
    return invokeLambda(environment.manifestLambda)
      .then(json => new Manifest(JSON.parse(json)))
      .then(manifest => {
        this.catalogs$.next(manifest.catalogs ?? []);

        const languages = Object.keys(manifest.i18n).map(lang => {
          const [Code, Name] = lang.split('_');
          // WARN: Impurity
          localStorage.setItem(Code, JSON.stringify(manifest.i18n[lang]));
          return {Code, Name};
        });
        this.languages$.next(languages ?? []);
        this.currencies$.next(['€', '$', 'лев', 'kroner', 'złoty', 'krona', 'koruna', 'leu', 'CHF', '£', '₴',
          'R$', 'Rs', 'đồng', '฿', 'S$', 'peso', 'roupie', 'RM', '₺', 'rial', 'riyal', 'dinar', '¥', '元', '₽', 'R',
          'F CFA']);
      });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth           = Auth;
  authenticated$ = new BehaviorSubject<UserType>(UserType.User);

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private http: HttpClient) {
    this.authenticated$.subscribe(type => {
      if (type !== UserType.UnAuth) {
        router
          .navigateByUrl(activatedRoute.snapshot.queryParamMap.get('returnUrl') ||
            (location.path().startsWith('/sign-in') ? '/home' : location.path()))
          .catch(console.error);
      } else {
        router
          .navigateByUrl(
            router.createUrlTree(
              ['sign-in'],
              {
                queryParams: {
                  returnUrl: location.path().startsWith('/sign-in') ? '/home' : location.path()
                }
              })
          )
          .catch(console.error);
      }
    });
    this.authenticate().catch(console.error);
  }

  checkEmail(email: string): Promise<UserType> {
    return invokeLambda(environment.emailLambda, {email})
      .then(response => Number(response) as UserType);
  }

  isGoogleAccount(email: string): Promise<boolean> {
    return this.http
      .get<any>(`https://dns.google.com/resolve?name=${email.split('@').pop()}&type=MX`)
      .toPromise()
      .then(res => res.Answer.some(answer => answer.data.endsWith('google.com.')));
  }

  newUserRequest(email: string): Promise<void> {
    return invokeLambda(environment.askUserCreationLambda, {email}).then();
  }

  authenticate(): Promise<void> {
    return Auth.currentAuthenticatedUser()
      .then(user => this.checkEmail(user.email))
      .then(userType => {
        switch (userType) {
          case UserType.Admin: {
            if (this.authenticated$.value !== UserType.Admin) {
              this.authenticated$.next(UserType.Admin);
            }
            break;
          }
          case UserType.User: {
            if (this.authenticated$.value !== UserType.User) {
              this.authenticated$.next(UserType.User);
            }
            break;
          }
          case UserType.UnAuth: {
            if (this.authenticated$.value !== UserType.UnAuth) {
              this.authenticated$.next(UserType.UnAuth);
            }
            break;
          }
          default:
            if (this.authenticated$.value !== UserType.UnAuth) {
              this.authenticated$.next(UserType.UnAuth);
            }
            break;
        }
      })
      .catch(_ => {
        if (this.authenticated$.value !== UserType.UnAuth) {
          this.authenticated$.next(UserType.UnAuth);
        }
      });
  }
}

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  get currentVersionName(): string {
    return `WATERAPP_CURRENT_VERSION_NAME::${this.appService.catalog$.value?.Code}`;
  }

  get currentVersionContent(): string {
    return `WATERAPP_CURRENT_VERSION_CONTENT::${this.appService.catalog$.value?.Code}`;
  }

  get regex(): RegExp {
    return new RegExp(`${this.appService.catalog$.value?.Code}\/(.*)\/database.json`);
  }


  private static mailHeader =
                   `<!doctype html><html lang="en"><head><meta charset="UTF-8"><style type="text/css">table {border-collapse: collapse;width: 100%;margin-bottom: 1rem;border: 1px solid #dee2e6;}thead tr.sub th {color: white;background-color: #8492a6;}thead th, thead td {border-bottom-width: 2px;text-transform: uppercase;text-align: center;color: white;background-color: #375f9b;}thead th {vertical-align: bottom;}</style><title></title></head><body>`;
  private static mailFooter = `</body></html>`;
  connected$                = new BehaviorSubject<boolean>(true);
  update$                   = new BehaviorSubject<boolean>(false);
  currentVersion$           = new BehaviorSubject<string>(localStorage.getItem(this.currentVersionName));
  lastVersion$              = new BehaviorSubject<string>(localStorage.getItem(this.currentVersionName));
  db$                       = new BehaviorSubject<Database>(new Database(JSON.parse(localStorage.getItem(this.currentVersionContent))));

  constructor(private authService: AuthService,
              private appService: AppService,
              private translateService: TranslateService) {
    window.addEventListener('online', () => this.connected$.next(true));
    window.addEventListener('offline', () => this.connected$.next(false));
    appService.catalog$.subscribe(_ => {
      this.currentVersion$.next(localStorage.getItem(this.currentVersionName));
      this.db$.next(new Database(JSON.parse(localStorage.getItem(this.currentVersionContent))));
      if (authService.authenticated$.value !== UserType.UnAuth && this.connected$.value) {
        return this.lastVersion();
      }
    });

    this.db$.subscribe(db => {
      this.appService.locked$.next(this.appService.defaultLocked$.value);
      this.appService.password      = db.parameters?.advancedPassword ?? '1234';
      this.appService.questionnaire = db.parameters?.questionnaire ?? [];
      this.appService.estimation    = db.parameters?.estimation ?? [];
    });

    timer(0, environment.checkUpdateInterval).subscribe(_ => {
      if (authService.authenticated$.value !== UserType.UnAuth && this.connected$.value) {
        return this.appService.manifest().then(() => this.lastVersion());
      }
    });
  }

  sendMail(payload: SendMailPayload): Promise<void> {
    return invokeLambda(environment.sendMailLambda, payload).then();
  }

  sendProjectMail(subject: string, body: string): Promise<void> {
    return Auth.currentAuthenticatedUser()
      .then(user => this.sendMail({
        To     : [{Address: user.email, DisplayName: user.name}],
        Cc     : [{Address: this.db$.value.parameters.recipient}],
        Subject: this.translateService.instant(subject),
        Body   : `${SyncService.mailHeader}${body}${SyncService.mailFooter}`
      }));
  }

  lastVersion(): Promise<string> {
    return Auth.currentUserCredentials()
      .then(cred => new S3({
          region     : environment.region,
          credentials: Auth.essentialCredentials(cred)
        }).listObjectsV2({
          Bucket: environment.S3Bucket,
          Prefix: this.appService.catalog$.value?.Code ?? ''
        })
      )
      .then(result =>
        result
          ?.Contents
          ?.sort((a, b) => b.LastModified.getTime() - a.LastModified.getTime())
          ?.find(_ => true)
          ?.Key
      )
      .then(key => this.regex.test(key) ? this.regex.exec(key)[1] : null)
      .then(version => {
        if (version !== this.lastVersion$.value) {
          this.lastVersion$.next(version);
        }
        return version;
      });
  }

  sync(): Promise<void> {
    const IMAGE_FOLDER = 'images/';
    type flowParam = { key: string, reader: ReadableStreamReader, contentType: string };

    const handleFile = <T>(s3: S3, key: string, customFlow: (_: flowParam) => Promise<T>): Promise<T> => {
      return s3
        .getObject({
          Bucket: environment.S3Bucket,
          Key: key
        })
        .then(s3Object => ({
          key,
          contentType: s3Object.ContentType,
          reader     : (s3Object.Body as ReadableStream).getReader()
        }))
        .then(customFlow);
    };

    const saveDB = ({reader}): Promise<Database> => {
      const readJson = async (decoder: TextDecoder = new TextDecoder(), json: string = ''): Promise<string> => {
        const {done, value} = await reader.read();
        if (!done) {
          json += decoder.decode(value.buffer, {stream: true});
          return await readJson(decoder, json);
        }
        return json;
      };

      return readJson().then(db => {
          localStorage.setItem(this.currentVersionName, version);
          localStorage.setItem(this.currentVersionContent, db);
          return new Database(JSON.parse(db));
        }
      );
    };

    const saveImage = ({reader, key, contentType}): Promise<void> => {
      const readImage = async (data: Uint8Array[] = []): Promise<Blob> => {
        const {done, value} = await reader.read();
        if (!done) {
          data.push(value);
          return await readImage(data);
        }
        return new Blob(data, {type: contentType});
      };

      return readImage().then(image => localForage.setItem(key.split(IMAGE_FOLDER).pop(), image)).then(_ => Promise.resolve());
    };

    const listImages = async (s3: S3, token: string = null): Promise<string[]> => {
      const opts: any = {
        Bucket: environment.S3Bucket,
        Prefix: `${this.appService.catalog$.value.Code}/${this.lastVersion$.value}/${IMAGE_FOLDER}`
      };
      if (token) {
        opts.ContinuationToken = token;
      }
      const {
              Contents             : contents,
              IsTruncated          : isTruncated,
              NextContinuationToken: nextToken
            } = await s3.listObjectsV2(opts);
      return [...(contents ?? []).map(i => i.Key), ...(isTruncated ? await listImages(s3, nextToken) : [])];
    };

    const version = this.lastVersion$.value;

    return Auth.currentUserCredentials()
      .then(async cred => {
          const s3 = new S3({
            region     : environment.region,
            credentials: Auth.essentialCredentials(cred)
          });
          return Promise.all([
            handleFile(s3, `${this.appService.catalog$.value.Code}/${version}/database.json`, saveDB),
            localForage
              .clear()
              .then(() => listImages(s3))
              .then(imageKeys => Promise.all(imageKeys.map(key => handleFile(s3, key, saveImage))))
          ]);
        }
      )
      .then(([db]) => {
        this.currentVersion$.next(version);
        this.db$.next(db as Database);
      })
      .catch(err => {
        console.error(err);
        localStorage.removeItem(this.currentVersionName);
        localStorage.removeItem(this.currentVersionContent);
        throw err;
      });
  }

  newVersion(version: string): Promise<string> {
    return invokeLambda(environment.syncLambda, {version, folderId: this.appService.catalog$.value.Code})
      .then(() => this.lastVersion());
  }
}

export const invokeLambda = (lambdaName: string, payload: { [key: string]: unknown } = {}): Promise<string> => {
  return Auth.currentUserCredentials()
    .then(cred => {
      const lambda = new Lambda({
        region     : environment.region,
        credentials: Auth.essentialCredentials(cred)
      });
      return lambda
        .invoke({
          FunctionName  : environment.arnLambda + lambdaName,
          InvocationType: 'RequestResponse',
          LogType       : 'None',
          Payload       : new TextEncoder().encode(JSON.stringify(payload))
        })
        .then(response => new TextDecoder().decode(response.Payload));
    });
};

const groupByOne = (items: { [name: string]: any; }[], key: string): { [name: string]: any[]; } => items?.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

export const groupBy = (items: { [name: string]: any; }[], ...keys: string[]): { [name: string]: any[]; } => {
  const [key, ...rest] = keys;
  const res            = groupByOne(items, key);
  if (rest.length > 0) {
    for (const objKey of Object.keys(res)) {
      res[objKey] = groupBy(res[objKey], ...rest) as any;
    }
  }
  return res;
};
