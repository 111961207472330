import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../shared/service';

@Component({
  selector   : 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls  : ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {

  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { username: string, code?: string },
              private dialogRef: MatDialogRef<ConfirmAccountComponent>,
              private authService: AuthService,
              private translate: TranslateService,
              private snackBar: MatSnackBar) {
  }


  ngOnInit(): void {
  }

  confirmCode(): void {
    this.loading = true;
    this.authService.auth.confirmSignUp(this.data.username, this.data.code)
      .then(() => this.dialogRef.close(true))
      .catch(err => this.snackBar.open(err, 'X'))
      .finally(() => this.loading = false);
  }

  resendCode(): void {
    this.loading = true;
    this.authService.auth.resendSignUp(this.data.username)
      .then(() => this.snackBar.open(this.translate.instant('SUCCESS_RESEND_CODE'), 'X'))
      .catch(_ => this.snackBar.open(this.translate.instant('ERROR_RESEND_CODE'), 'X'))
      .finally(() => this.loading = false);
  }

}
