import {Location} from '@angular/common';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {AppService, AuthService, SyncService, UserType} from '../shared/service';

@Component({
  selector   : 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls  : ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];

  next: () => void;
  back: () => void;
  alternateBack: () => void;
  loading  = false;
  UserType = UserType;

  @ViewChild('content') content: ElementRef;

  constructor(public syncService: SyncService,
              public authService: AuthService,
              public appService: AppService,
              private router: Router,
              private location: Location) {
  }


  ngOnInit(): void {
    this.subscriptions.push(
      this.syncService.lastVersion$.subscribe(version => {
        if (version !== this.syncService.currentVersion$.value) {
          this.router
            .navigateByUrl(this.router.createUrlTree(['synchronization']))
            .catch(err => console.error(null, err));
        }
      }),
      this.appService.nav$
        .pipe(delay(0))
        .subscribe((args) => {
          this.back          = null;
          this.alternateBack = null;
          this.next          = null;

          if (!args) {
            return;
          } else if (args instanceof Function) {
            this.back = this.alternateBack = () => args(this.router, this.location);
          } else {
            const {next, back, alternateBack} = args;
            this.back                         = () => back(this.router, this.location);
            this.alternateBack                = alternateBack ? (() => alternateBack(this.router, this.location)) : this.back;
            this.next                         = next && (() => next(this.router, this.location));
          }

        })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.appService.scrollTop$.subscribe(() => {
        this.content.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
      })
    );
  }

  logout(): void {
    this.loading = true;
    this.authService.auth.signOut()
      .then(_ => this.authService.authenticate())
      .catch(console.error)
      .finally(() => this.loading = false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
