<mat-card class="py-3 mx-5 my-3">
  <div class="row align-items-center">
    <div class="col">
      {{ 'CATALOG' | translate }} :
    </div>
    <div class="col-5">
      <mat-form-field class="w-100">
        <mat-select [value]="(appService.catalog$ | async)" [compareWith]="appService.comparer"
                    (selectionChange)="appService.catalog$.next($event.value)">
          <mat-option *ngFor="let l of appService.catalogs$ | async" [value]="l">{{ l.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col">
      {{ 'LANGUAGE' | translate }} :
    </div>
    <div class="col-5">
      <mat-form-field class="w-100">
        <mat-select [value]="appService.language$ | async" [compareWith]="appService.comparer"
                    (selectionChange)="appService.language$.next($event.value)">
          <mat-option *ngFor="let l of appService.languages$ | async" [value]="l">{{ l.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col">
      {{ 'CURRENCY' | translate }} :
    </div>
    <div class="col-5">
      <mat-form-field class="w-100">
        <mat-select (selectionChange)="appService.currency$.next($event.value)"
                    [value]="appService.currency$ | async">
          <mat-option *ngFor="let c of appService.currencies$ | async" [value]="c">{{ c }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col">
      {{ 'UNIT_SYSTEM' | translate }} :
    </div>
    <div class="col-5">
      <mat-form-field class="w-100">
        <mat-select (selectionChange)="appService.imperial$.next($event.value)"
                    [value]="appService.imperial$ | async">
          <mat-option [value]="false">{{ 'METRIC' | translate }}</mat-option>
          <mat-option [value]="true">{{ 'IMPERIAL' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col">
      {{ 'DEFAULT_MODE' | translate }} :
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-select [value]="appService.defaultLocked$ | async"
                    (selectionChange)="appService.defaultLocked$.next($event.value)"
                    [disabled]="appService.locked$ | async">
          <mat-option [value]="true">{{ 'BASIC_MODE' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'ADVANCED_MODE' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-1">
      <button *ngIf="appService.locked$ | async" mat-icon-button class="lock" (click)="appService.unlock()">
        <mat-icon>lock</mat-icon>
      </button>
      <button *ngIf="!(appService.locked$ | async)" mat-icon-button class="lock" (click)="appService.locked$.next(true)">
        <mat-icon>lock_open</mat-icon>
      </button>
    </div>
  </div>
</mat-card>
