<!--suppress HtmlFormInputWithoutLabel -->
<div class="py-2 px-4">
  <div class="row align-items-center">
    <div class="col">
      <span class="h5 font-weight-light">{{ 'RESET_PASSWORD' | translate }}</span>
    </div>
    <div class="col-2 px-0 text-right">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="py-4">

  <div [class.d-none]="step !== 0">
    <form #emailForm="ngForm">
      <mat-form-field class="w-100">
        <mat-label>{{ 'EMAIL' | translate }}</mat-label>
        <input matInput type="email" required email [(ngModel)]="form.username" #username="ngModel" name="username">
        <mat-error *ngIf="username.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
        <mat-error *ngIf="username.errors?.email">{{ 'FIELD_EMAIL' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </div>


  <div [class.d-none]="step !== 1">
    <form #resetForm="ngForm">
      <mat-form-field class="w-100">
        <mat-label>{{ 'CONFIRMATION_CODE' | translate }}</mat-label>
        <input matInput type="text" required [(ngModel)]="form.code" #code="ngModel" name="code">
        <mat-error *ngIf="code.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
        <input matInput type="password" required [(ngModel)]="form.password" name="password" #password="ngModel">
        <mat-error *ngIf="password.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="font-weight-bold text-center text-muted small" [class.d-none]="step !== 2">
    <div class="mb-2">{{ 'PASSWORD_RESET' | translate }}</div>
    <div>{{ 'PLEASE_SIGN_IN' | translate }}</div>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="pt-0 pb-4 px-4">
  <button mat-raised-button color="primary"
          class="w-100"
          [disabled]="(step === 0 && emailForm.invalid) || (step === 1 && resetForm.invalid)"
          (click)="reset()"
          type="button">
    <span *ngIf="!loading">
      {{ step === 2 ? 'OK' : 'RESET_PASSWORD' | translate }}
    </span>
    <mat-spinner *ngIf="loading" class="btn-spinner"></mat-spinner>
  </button>
</mat-dialog-actions>
