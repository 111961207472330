<mat-progress-bar mode="indeterminate" *ngIf="loading" class="position-fixed"></mat-progress-bar>
<div class="container text-center py-3 h-100">
  <mat-form-field>
    <mat-label>{{ "CATALOG" | translate }}</mat-label>
    <mat-select [value]="(appService.catalog$ | async)" [compareWith]="appService.comparer" (selectionChange)="appService.catalog$.next($event.value)">
      <mat-option *ngFor="let catalog of (appService.catalogs$ | async)" [value]="catalog">{{catalog.Name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="text-primary mb-3">
    {{ 'ACTIVE_VERSION' | translate }} : {{ syncService.currentVersion$ | async }}
  </div>
  <div class="text-primary">
    {{ 'LAST_VERSION' | translate }} : {{ syncService.lastVersion$ | async }}
  </div>

  <div class="btn-wrapper">
    <button mat-raised-button color="primary" (click)="sync()" [disabled]="loading || (syncService.lastVersion$ | async) === (syncService.currentVersion$ | async)">
      {{ 'SYNCHRONIZE' | translate }}
    </button>
  </div>
</div>
