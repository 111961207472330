<!--suppress HtmlFormInputWithoutLabel -->
<form (ngSubmit)="checkAndSignUp()" #signUpForm="ngForm">
  <div class="py-2 px-4">
    <div class="row align-items-center">
      <div class="col">
        <span class="h5 font-weight-light">{{ 'SIGN_UP' | translate }}</span>
      </div>
      <div class="col-2 px-0 text-right">
        <button mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-dialog-content class="py-4">
    <div class="row w-100 no-gutters">
      <div class="col">
        <mat-form-field class="w-100">
          <mat-label>{{ 'EMAIL' | translate }}</mat-label>
          <input matInput type="email" required email [(ngModel)]="form.username" name="email" #email="ngModel">
          <mat-error *ngIf="email.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
          <mat-error *ngIf="email.errors?.email">{{ 'FIELD_EMAIL' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
          <input matInput type="password" id="password" required [(ngModel)]="form.password" name="password" #password="ngModel">
          <mat-error *ngIf="password.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
        </mat-form-field>
        <div class="text-center">
          <button mat-flat-button color="primary" type="submit" [disabled]="!signUpForm.form.valid">
            {{ 'SIGN_IN' | translate | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="pt-0 pb-4 px-4">
    <div class="row w-100 no-gutters">
      <div class="col text-right">
        <button mat-raised-button class="w-100" type="submit" color="primary" [disabled]="!signUpForm.form.valid">
          <span *ngIf="!loading">
            {{ 'SIGN_UP' | translate }}
          </span>
          <mat-spinner *ngIf="loading" class="btn-spinner"></mat-spinner>
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</form>
