import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';

export type Company = {
  name: string,
  site: string,
  email: string
};

@Component({
  selector   : 'app-company-step',
  templateUrl: './company-step-project.component.html',
  styleUrls  : ['../project.component.scss', './company-step-project.component.scss'],
})
export class CompanyStepProjectComponent implements OnInit, AfterViewInit {

  @Output() company = new EventEmitter<Company>();
  @ViewChild('companyForm') companyForm: NgForm;


  form: Company = {
    name : '',
    site : '',
    email: ''
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.companyForm.valueChanges.subscribe(value => this.company.emit(value));
  }
}
