<!--suppress HtmlFormInputWithoutLabel -->
<div class="py-2 px-4">
  <div class="row align-items-center">
    <div class="col">
      <span class="h5 font-weight-light">{{ 'CONFIRM_ACCOUNT' | translate }}</span>
    </div>
    <div class="col-2 px-0 text-right">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="py-4">

  <div>
    <form #confirmForm="ngForm">
      <mat-form-field class="w-100">
        <mat-label>{{ 'CONFIRMATION_CODE' | translate }}</mat-label>
        <input matInput type="text" required [(ngModel)]="data.code" name="code" #code="ngModel" />
        <mat-error *ngIf="code.errors?.required">{{ 'FIELD_REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </form>
    <span class="text-muted cursor-pointer" (click)="resendCode()">{{ 'RESEND_CODE' | translate }}</span>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="pt-0 pb-4 px-4">
  <button mat-raised-button color="primary"
          class="w-100"
          [disabled]="confirmForm.invalid"
          (click)="confirmCode()">
    <span *ngIf="!loading">
      {{ 'CONFIRM' | translate }}
    </span>
    <mat-spinner *ngIf="loading" class="btn-spinner"></mat-spinner>
  </button>
</mat-dialog-actions>
