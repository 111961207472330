<div class="container py-3">
  <img *ngIf="equipment.header" [src]="equipment.header | fromStorage | async" alt="header">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{'DESCRIPTION' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content [innerText]="equipment.description"></mat-card-content>
    <mat-card-header>
      <mat-card-title>{{'FEATURES' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngFor="let feature of equipment.features" [innerText]="feature">
    </mat-card-content>
  </mat-card>
  <mat-card class="table-responsive inner mb-5">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th scope="col" *ngFor="let header of equipment.table.header"
            class="text-center text-content">
          {{ header }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of equipment.table.content">
        <td *ngFor="let cell of row; let y = index" class="{{ y === 0 ? 'head-col' : 'value' }}">
          <span>{{ cell }}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card>
  <img *ngIf="equipment.footer" [src]="equipment.footer | fromStorage | async" alt="footer">
</div>
