import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {Amplify} from '@aws-amplify/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Amplify.configure({
  Auth: {
    identityPoolId     : environment.identityPoolId,
    region             : environment.region,
    userPoolId         : environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
