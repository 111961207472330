<div class="container py-3 discovery">
  <ng-container *ngIf="!focusOnLast || journey.length !== steps.length">
    <ng-container *ngFor="let _ of steps; index as idx; let last = last">
      <ul *ngIf="displayStep(steps[idx])"
          [class.d-block]="idx+1 === journey.length"
          class="list-unstyled d-none d-md-block">
        <li *ngFor="let step of steps[idx]"
            [class.active]="last || idx+1 === journey.length || step === journey[idx + 1]"
            class="mb-3">
          <a [routerLink]="last ? ['/' + detail + '/' + step.folio ] : ['.', {journey: explore(idx, step)}]"
             class="tile row align-items-center p-3 text-white">
            <span class="col-10">{{ last ? step.folio : step }}</span>
            <span class="col-2 text-right"><mat-icon class="align-middle">keyboard_arrow_right</mat-icon></span>
          </a>
          {{step | image: last: focusOnLast}}
          <img
            *ngIf="step | image: last : focusOnLast"
            [src]="step | image: last : focusOnLast| fromStorage | async"
            alt="logo">
          <span class="overlay"></span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngFor="let _ of steps; index as idx; let last = last">
      <ul *ngIf="!displayStep(steps[idx])"
          [class.d-block]="idx+1 === journey.length"
          class="list-unstyled d-none d-md-block"></ul>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="focusOnLast && journey.length === steps.length">
    <ul class="list-unstyled d-block focus">
      <li *ngFor="let step of steps[steps.length - 1]" class="mb-3">
        <a [routerLink]="['/' + detail + '/' + step.folio ]"
           class="text-white">
          <span>{{ step.folio }}</span>
          <span>
            <img [src]="step | image: true: focusOnLast | fromStorage | async" alt="logo">
          </span>
        </a>
      </li>
    </ul>
  </ng-container>
</div>
