import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {AppService, SyncService} from '../../../shared/service';
import {Company} from '../company-step/company-step-project.component';

export type TertiaryForm = {
  company: Company,
  product: string,
  flowRate: number,
  codSent: number,
  pollutant: 'POLLUTANT_MICRO' | 'POLLUTANT_METALS' | 'POLLUTANT_MEDICAL' | 'POLLUTANT_COSMETIC' | 'POLLUTANT_INSECTICIDE' | 'POLLUTANT_CYANIDE' | 'POLLUTANT_OTHER',
  description: string,
  concentration: number,
  requirement: {
    type: 'REQUIREMENT_COD' | 'REQUIREMENT_POLLUTANT',
    unit: '%_OF_REMOVAL' | 'MG_L',
    value: number
  },
  trouble: {
    removalEfficiency: boolean,
    bulking: boolean,
    colourRemoval: boolean,
    overload: boolean,
    odor: boolean,
    foaming: boolean,
    highSvi: {
      active: boolean,
      value: number
    }
  }
};

@Component({
  selector   : 'app-tertiary-project',
  templateUrl: './tertiary-project.component.html',
  styleUrls  : ['../project.component.scss', './tertiary-project.component.scss']
})
export class TertiaryProjectComponent implements OnInit, AfterViewInit, AfterViewChecked {


  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('emailContent') emailContent: ElementRef;
  htmlMail: string;

  loading            = false;
  form: TertiaryForm = {
    company      : {
      name : '',
      site : '',
      email: ''
    },
    product      : '',
    flowRate     : 0,
    codSent      : 0,
    pollutant    : 'POLLUTANT_MICRO',
    description  : '',
    concentration: 0,
    requirement  : {
      type : 'REQUIREMENT_COD',
      unit : 'MG_L',
      value: 0
    },
    trouble      : {
      removalEfficiency: false,
      bulking          : false,
      colourRemoval    : false,
      overload         : false,
      odor             : false,
      foaming          : false,
      highSvi          : {
        active: false,
        value : 0
      }
    }
  };

  constructor(private appService: AppService,
              private syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('TERTIARY_PROJECT');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/project'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/project'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.htmlMail = this.emailContent.nativeElement.innerHTML, 0);
  }

  onCompanyChanged(company: Company): void {
    this.form.company = company;
  }

  sendMail(): void {
    this.loading = true;
    this.syncService.sendProjectMail('REMINERALIZATION_PROJECT', this.htmlMail)
      .finally(() => this.loading = false);
  }
}
