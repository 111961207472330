import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Competitor, EquipmentPortfolio, SolutionPortfolio} from '../../shared/model';
import {AppService, groupBy, SyncService} from '../../shared/service';

@Component({
  selector   : 'app-discovery',
  templateUrl: './discovery.component.html',
  styleUrls  : ['./discovery.component.scss']
})
export class DiscoveryComponent implements OnInit {

  private data;
  guide: 'equipmentPortfolio' | 'solutionPortfolio' | 'competitors';
  detail: 'equipments' | 'solutions' | 'competitors';
  journeyTemplate: string[]                                                 = [];
  journey: string[]                                                         = [];
  steps: (string | SolutionPortfolio | EquipmentPortfolio | Competitor)[][] = [];
  focusOnLast                                                               = false;

  constructor(
    private appService: AppService,
    private syncService: SyncService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.appService.title$.next(data.destination);
      this.focusOnLast = data.focusOnLast ?? false;
      this.guide       = data.guide;
      switch (data.guide) {
        case 'equipmentPortfolio':
          this.detail = 'equipments';
          break;
        case 'solutionPortfolio':
          this.detail          = 'solutions';
          this.journeyTemplate = data.journey;
          break;
        case 'competitors':
          this.detail = 'competitors';
          break;
      }
      this.data  = groupBy(this.syncService.db$.value[data.guide], ...data.journey);
      this.steps = Array(data.journey.length + 1).fill(null);
    });
    this.route.params.subscribe(params => {
      const journey = params.journey ? JSON.parse(params.journey) : [];
      this.journey  = [null, ...journey];
      this.appService.nav$.next({
        back         : router => this.focusOnLast ?
          journey.length === this.steps.length - 1 ?
            router.navigate(['.', {journey: JSON.stringify(journey.slice(0, -1))}]) :
            router.navigateByUrl('/home')
          : router.navigateByUrl('/home'),
        alternateBack: router => journey.length === 0 ?
          router.navigateByUrl('/home') :
          router.navigate(['.', {journey: JSON.stringify(journey.slice(0, -1))}])
      });
      this.appService.scrollTop$.next();
      this.setupSteps();
    });
  }

  explore = (index: number, step: string): string => {
    let proposedJourney = [...this.journey.slice(1, index + 1), step];
    const nextSteps     = Object.keys(proposedJourney.reduce((acc, curr) => acc[curr], this.data));
    if (nextSteps.length === 1 && nextSteps[0] === '') {
      proposedJourney = [...proposedJourney, ''];
    }
    return JSON.stringify(proposedJourney);
  }

  setupSteps(): void {
    let tmp = this.data;
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i] = tmp ?
        (i === this.steps.length - 1 ?
            tmp :
            this.sortBySelectedThenAlpha(tmp, i)
        ) : [];
      tmp           = tmp?.[this.journey[i + 1]];
    }
  }

  displayStep(step): boolean {
    return step.length !== 1 || !!step[0];
  }

  sortBySelectedThenAlpha(obj: string[], i: number): string[] {
    return Object.keys(obj)
      .sort((a, b) => {
        if (a === this.journey[i + 1]) {
          return -1;
        }
        if (b === this.journey[i + 1]) {
          return 1;
        }
        return a.localeCompare(b);
      });
  }
}
