import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../shared/service';

@Component({
  selector   : 'app-cost-step',
  templateUrl: './cost-step-estimation.component.html',
  styleUrls  : ['../estimation.component.scss', './cost-step-estimation.component.scss'],
})
export class CostStepEstimationComponent implements OnInit {

  @Input() public weight = 0;
  @Input() public volume = null;

  form = {
    unit : 'KG',
    price: 0
  };

  get value(): number {
    return this.form.unit === 'M3'
      ? this.volume
      : this.weight / (this.form.unit === 'T' ? 1000 : 1);
  }

  constructor(public appService: AppService) {
  }

  ngOnInit(): void {
  }

}
