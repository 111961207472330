import {Component, Input, ViewEncapsulation} from '@angular/core';

export type Company = {
  name: string,
  site: string,
  email: string
};

@Component({
  selector     : 'app-mail-step',
  templateUrl  : './mail-step-project.component.html',
  styleUrls    : ['../project.component.scss', './mail-step-project.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class MailStepProjectComponent {

  @Input() htmlMail: string;

  constructor() {
  }
}
