import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdministrationComponent} from './navigation/administration/administration.component';
import {CompetitorComponent} from './navigation/competitor/competitor.component';
import {DiscoveryComponent} from './navigation/discovery/discovery.component';
import {EquipmentComponent} from './navigation/equipment/equipment.component';
import {AcidEstimationComponent} from './navigation/estimation/acid/acid-estimation.component';
import {BiologicalEstimationComponent} from './navigation/estimation/biological/biological-estimation.component';
import {EstimationComponent} from './navigation/estimation/estimation.component';
import {O3EstimationComponent} from './navigation/estimation/o3/o3-estimation.component';
import {RemineralizationEstimationComponent} from './navigation/estimation/remineralization/remineralization-estimation.component';
import {RemovalEstimationComponent} from './navigation/estimation/removal/removal-estimation.component';
import {FaqComponent} from './navigation/faq/faq.component';
import {HomeComponent} from './navigation/home/home.component';
import {InformationComponent} from './navigation/information/information.component';
import {NavigationComponent} from './navigation/navigation.component';
import {BiologicalProjectComponent} from './navigation/project/biological/biological-project.component';
import {GroundWaterProjectComponent} from './navigation/project/groundwater/ground-water-project.component';
import {PhControlProjectComponent} from './navigation/project/ph-control/ph-control-project.component';
import {ProjectComponent} from './navigation/project/project.component';
import {RemineralizationProjectComponent} from './navigation/project/remineralization/remineralization-project.component';
import {SludgeProjectComponent} from './navigation/project/sludge/sludge-project.component';
import {TertiaryProjectComponent} from './navigation/project/tertiary/tertiary-project.component';
import {SettingsComponent} from './navigation/setting/settings.component';
import {SolutionComponent} from './navigation/solution/solution.component';
import {SynchronizationComponent} from './navigation/synchronization/synchronization.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AdvancedGuard, AuthGuard, ManifestGuard, SettingsGuard, SyncGuard} from './shared/guard';
import {SignInComponent} from './sign-in/sign-in.component';


const routes: Routes = [
  {path: 'sign-in', component: SignInComponent},
  {
    path       : '',
    component  : NavigationComponent,
    canActivate: [AuthGuard, ManifestGuard],
    children   : [
      {path: 'synchronization', component: SynchronizationComponent},
      {path: 'administration', component: AdministrationComponent},
      {path: 'information', component: InformationComponent},
      {path: 'settings', component: SettingsComponent, canActivate: [SyncGuard]},
      {path: 'home', component: HomeComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'solutions/:name', component: SolutionComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'equipments/:name', component: EquipmentComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'faq', component: FaqComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'estimation/o3', component: O3EstimationComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'estimation/acid', component: AcidEstimationComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'estimation/removal', component: RemovalEstimationComponent, canActivate: [SyncGuard, SettingsGuard]},
      {
        path       : 'estimation/biological',
        component  : BiologicalEstimationComponent,
        canActivate: [SyncGuard, SettingsGuard]
      },
      {
        path       : 'estimation/remineralization',
        component  : RemineralizationEstimationComponent,
        canActivate: [SyncGuard, SettingsGuard]
      },
      {path: 'estimation', component: EstimationComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'project/sludge', component: SludgeProjectComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'project/tertiary', component: TertiaryProjectComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'project/biological', component: BiologicalProjectComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'project/ph-control', component: PhControlProjectComponent, canActivate: [SyncGuard, SettingsGuard]},
      {path: 'project/groundwater', component: GroundWaterProjectComponent, canActivate: [SyncGuard, SettingsGuard]},
      {
        path       : 'project/remineralization',
        component  : RemineralizationProjectComponent,
        canActivate: [SyncGuard, SettingsGuard]
      },
      {path: 'project', component: ProjectComponent, canActivate: [SyncGuard, SettingsGuard]},
      {
        path       : 'portfolio',
        component  : DiscoveryComponent,
        data       : {
          destination: 'PORTFOLIO',
          journey    : ['gas', 'water', 'usage', 'layout'],
          guide      : 'equipmentPortfolio',
          focusOnLast: true
        },
        canActivate: [SyncGuard, SettingsGuard]
      },
      {
        path       : 'selector',
        component  : DiscoveryComponent,
        data       : {
          destination: 'SOLUTION_SELECTOR',
          journey    : ['context', 'needs', 'extraNeeds'],
          guide      : 'solutionPortfolio'
        },
        canActivate: [SyncGuard, SettingsGuard]
      },
      {
        path       : 'competitors',
        component  : DiscoveryComponent,
        data       : {
          destination: 'COMPETITOR',
          journey    : ['name', 'gas'],
          guide      : 'competitors'
        },
        canActivate: [SyncGuard, SettingsGuard, AdvancedGuard]
      },
      {
        path       : 'competitors/:name',
        component  : CompetitorComponent,
        canActivate: [SyncGuard, SettingsGuard, AdvancedGuard]
      },
    ]
  },
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
