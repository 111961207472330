import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {AppService, SyncService} from '../../../shared/service';
import {Company} from '../company-step/company-step-project.component';

export type BiologicalForm = {
  company: Company,
  product: string,
  flowRate: number,
  cod: number,
  nTotal: { type: 'INLET_NITROGEN' | 'INLET_AMMONIUM', value: number },
  dissolved: number,
  requirement: {
    cod: { unit: '%_OF_REMOVAL' | 'MG_L', value: number },
    nitrogen: { unit: '%_OF_REMOVAL' | 'MG_L', value: number },
  },
  trouble: {
    overload: boolean,
    odor: boolean,
    sludge: boolean,
    foaming: boolean,
    bacteria: boolean,
  }
};

@Component({
  selector   : 'app-estimation',
  templateUrl: './biological-project.component.html',
  styleUrls  : ['../project.component.scss', './biological-project.component.scss']
})
export class BiologicalProjectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('stepper') stepper: MatHorizontalStepper;
  @ViewChild('emailContent') emailContent: ElementRef;
  htmlMail: string;

  loading              = false;
  form: BiologicalForm = {
    company    : {
      name : '',
      site : '',
      email: ''
    },
    product    : '',
    flowRate   : 0,
    cod        : 0,
    nTotal     : {type: 'INLET_NITROGEN', value: 0},
    dissolved  : 0,
    requirement: {
      cod     : {unit: 'MG_L', value: 0},
      nitrogen: {unit: 'MG_L', value: 0},
    },
    trouble    : {
      overload: false,
      odor    : false,
      sludge  : false,
      foaming : false,
      bacteria: false,
    }
  };

  constructor(private appService: AppService,
              private syncService: SyncService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('BIOLOGICAL_PROJECT');
    this.appService.nav$.next({
      back: router => router.navigateByUrl('/project'),
      next: _ => this.stepper.next()
    });
  }

  ngAfterViewInit(): void {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      switch (selectedIndex) {
        case 0:
          this.appService.nav$.next({
            back: router => router.navigateByUrl('/project'),
            next: _ => this.stepper.next()
          });
          break;
        case (this.stepper.steps.length - 1):
          this.appService.nav$.next(_ => this.stepper.previous());
          break;
        default:
          this.appService.nav$.next({
            back: _ => this.stepper.previous(),
            next: _ => this.stepper.next()
          });
          break;
      }
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.htmlMail = this.emailContent.nativeElement.innerHTML, 0);
  }

  onCompanyChanged(company: Company): void {
    this.form.company = company;
  }

  sendMail(): void {
    this.loading = true;
    this.syncService.sendProjectMail('REMINERALIZATION_PROJECT', this.htmlMail)
      .finally(() => this.loading = false);
  }
}
