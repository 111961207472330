import {Component, OnInit} from '@angular/core';
import {AppService, SyncService} from '../../shared/service';

@Component({
  selector   : 'app-synchronization',
  templateUrl: './synchronization.component.html',
  styleUrls  : ['./synchronization.component.scss']
})
export class SynchronizationComponent implements OnInit {
  loading = false;

  constructor(public syncService: SyncService,
              public appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('SYNCHRONIZATION');
    this.appService.nav$.next(router => router.navigateByUrl('/home'));
  }

  sync(): void {
    this.loading = true;
    this.syncService.sync()
      .catch(console.error)
      .finally(() => this.loading = false);
  }
}
