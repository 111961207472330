<div class="py-4 px-5 confirm-dialog-wrapper">
  <h2 mat-dialog-title>{{ data.title | translate }}</h2>
  <mat-dialog-content>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div>
      <button mat-raised-button color="warn" (click)="this.dialogRef.close(false)">{{ data.cancelText  | translate }}</button>
    </div>
    <div class="flex-grow-1 text-right">
      <button mat-raised-button color="primary" (click)="this.dialogRef.close(true)">{{ data.confirmText  | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
