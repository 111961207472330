<!--suppress HtmlFormInputWithoutLabel -->
<mat-card>
  <form #companyForm="ngForm">
    <h4 class="mb-5" translate>COMPANY_FORM</h4>
    <div class="row">
      <div translate>COMPANY_NAME</div>
      <div>
        <mat-form-field>
          <mat-label translate>COMPANY_NAME</mat-label>
          <input #name="ngModel" [(ngModel)]="form.name" matInput name="name" type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div translate>COMPANY_SITE</div>
      <div>
        <mat-form-field>
          <mat-label translate>COMPANY_SITE</mat-label>
          <input #site="ngModel" [(ngModel)]="form.site" matInput name="site" type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div translate>COMPANY_EMAIL</div>
      <div>
        <mat-form-field>
          <mat-label translate>COMPANY_EMAIL</mat-label>
          <input #email="ngModel" [(ngModel)]="form.email" email matInput name="email" type="email">
          <mat-error *ngIf="email.errors?.email">{{ 'FIELD_EMAIL' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-card>
