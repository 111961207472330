import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AuthService, UserType} from '../../shared/service';
import {ConfirmComponent} from '../../shared/confirm/confirm.component';

@Component({
  selector   : 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls  : ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  loading = false;
  form    = {username: null, password: null};

  constructor(private matDialogRef: MatDialogRef<SignUpComponent>,
              private matDialog: MatDialog,
              private authService: AuthService,
              private translate: TranslateService,
              private snackbar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  checkAndSignUp(): void {
    this.loading = true;
    this.authService.checkEmail(this.form.username)
      .then(userType => {
        if (userType === UserType.UnAuth) {
          return this.matDialog
            .open(ConfirmComponent, {width: '350px', data: {title: 'ASK_ACCOUNT_CREATION'}})
            .afterClosed()
            .toPromise()
            .then(res =>
              res && this.authService.newUserRequest(this.form.username)
                .then(() => this.snackbar.open(this.translate.instant('AUTHORIZATION_ASKED'), 'X'))
                .catch(_ => this.snackbar.open(this.translate.instant('ERROR_ASK_USER_CREATION'), 'X'))
            )
            .then(Promise.resolve);
        }

        return this.authService.isGoogleAccount(this.form.username)
          .then(res => (
            res
            && this.snackbar.open(this.translate.instant('CONNECT_GOOGLE'), 'X')
            && Promise.resolve()
          ) || this.signUp());

      })
      .catch(err => this.snackbar.open(this.translate.instant(err?.message), 'X'))
      .finally(() => this.loading = false);
  }

  private signUp(): Promise<void> {
    return this.authService.auth.signUp({
      username      : this.form.username,
      password      : this.form.password,
      attributes    : {},
      validationData: []
    })
      .then(data => {
        this.snackbar.open(this.translate.instant('SUCCESS_ACCOUNT_CREATION'), 'X');
        this.matDialogRef.close(data.user);
      });
  }


}
