import {Component, OnInit} from '@angular/core';
import {AppService} from '../shared/service';

@Component({
  selector   : 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls  : ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private appService: AppService) {
  }

  ngOnInit(): void {
    this.appService.title$.next('FAQ');
    this.appService.nav$.next(router => router.navigateByUrl('/home'));
  }

}
