import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Competitor} from '../../shared/model';
import {AppService, SyncService} from '../../shared/service';

@Component({
  selector   : 'app-competitor',
  templateUrl: './competitor.component.html',
  styleUrls  : ['./competitor.component.scss']
})
export class CompetitorComponent implements OnInit {
  competitor: Competitor;

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    public syncService: SyncService) {
  }


  ngOnInit(): void {
    this.appService.nav$.next((_, location) => location.back());

    this.route.params.subscribe(params => {
      this.competitor = this.syncService.db$.value.competitors.find(f => f.folio === params.name);
      this.appService.title$.next(this.competitor.folio);
    });
  }
}
