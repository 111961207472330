<div class="container py-3">
  <ul class="list-unstyled">
    <li class="bg-secondary my-3">
      <a *ngIf="appService.estimation.includes('biological')" [routerLink]="['./biological']"
         class="row align-items-center p-3 text-white"
         title="{{ 'BIOLOGICAL_ESTIMATION' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'BIOLOGICAL_ESTIMATION' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.estimation.includes('acid')" [routerLink]="['./acid']"
         class="row align-items-center p-3 text-white"
         title="{{ 'ACID_ESTIMATION' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'ACID_ESTIMATION' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.estimation.includes('o3')" [routerLink]="['./o3']"
         class="row align-items-center p-3 text-white"
         title="{{ 'O3_ESTIMATION' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'O3_ESTIMATION' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.estimation.includes('remineralization')" [routerLink]="['./remineralization']"
         class="row align-items-center p-3 text-white"
         title="{{ 'REMINERALIZATION_ESTIMATION' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'REMINERALIZATION_ESTIMATION' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
    <li class="bg-secondary my-3">
      <a *ngIf="appService.estimation.includes('removal')" [routerLink]="['./removal']"
         class="row align-items-center p-3 text-white"
         title="{{ 'REMOVAL_ESTIMATION' | translate }}">
          <span class="offset-2 col-8 text-center">
            <span class="d-block text-center mt-2">
              {{ 'REMOVAL_ESTIMATION' | translate }}
            </span>
          </span>
        <span class="col-2 text-right">
            <mat-icon class="align-middle">keyboard_arrow_right</mat-icon>
          </span>
      </a>
    </li>
  </ul>
</div>
