import {ArrayTyper} from '@caliatys/array-typer';

export type Language = { Code: string, Name: string };
export type Catalog = { Code: string, Name: string };
export type MailAddress = { Address: string, DisplayName?: string };
export type SendMailPayload = {
  To: MailAddress[],
  Cc?: MailAddress[],
  Bcc?: MailAddress[],
  Subject: string,
  Body: string
};

export class Manifest {
  catalogs: Catalog[]              = [];
  i18n: { lang?: { key: string } } = {};

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.catalogs = json.Catalogs;
    this.i18n     = json.Interface;
  }
}

export class Database {
  solutionPortfolio: SolutionPortfolio[];
  equipmentPortfolio: EquipmentPortfolio[];
  equipments: Equipment[];
  solutions: Solution[];
  competitors: Competitor[];
  faq: Faq[];
  parameters: Parameters;
  images: { [key: string]: string };

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.solutionPortfolio  = ArrayTyper.asArray(SolutionPortfolio, json.SolutionPortfolio);
    this.equipmentPortfolio = ArrayTyper.asArray(EquipmentPortfolio, json.EquipmentPortfolio);
    this.equipments         = ArrayTyper.asArray(Equipment, json.Equipments);
    this.solutions          = ArrayTyper.asArray(Solution, json.Solutions);
    this.competitors        = ArrayTyper.asArray(Competitor, json.Competitors);
    this.faq                = ArrayTyper.asArray(Faq, json.Faq);
    this.parameters         = new Parameters(json.Parameters);
    this.images             = json.Images;
  }
}

export class Parameters {
  recipient: string;
  advancedPassword: string;
  questionnaire: string[];
  estimation: string[];

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.recipient        = json.recipient;
    this.advancedPassword = json.advancedPassword ?? '1234';
    this.questionnaire    = json.questionnaire ? json.questionnaire.split('||') : [];
    this.estimation       = json.estimation ? json.estimation.split('||') : [];
  }

}

export class SolutionPortfolio {
  context: string;
  needs: string;
  extraNeeds: string;
  folio: string;

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.context    = json.Context;
    this.needs      = json.Needs;
    this.extraNeeds = json.ExtraNeeds;
    this.folio      = json.Solution;
  }
}

export class EquipmentPortfolio {
  gas: string;
  water: string;
  usage: string;
  layout: string;
  folio: string;

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.gas    = json.Gas;
    this.water  = json.Water;
    this.usage  = json.Usage;
    this.layout = json.Layout;
    this.folio  = json.Equipment;
  }
}


export class Solution {
  name: string;
  header: string;
  footer: string;
  description: string;
  studies: CaseStudy[];

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.name        = json.Name;
    this.header      = json.Header;
    this.footer      = json.Footer;
    this.description = json.Description;
    this.studies     = ArrayTyper.asArray(CaseStudy, json.Studies);
  }
}

export class CaseStudy {
  needs: string;
  solution: string;
  benefits: string;

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.needs    = json.Needs;
    this.solution = json.Solution;
    this.benefits = json.Benefits;
  }
}

export class Equipment {
  name: string;
  logo: string;
  header: string;
  footer: string;
  description: string;
  features: string[];
  table: Table;

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.name        = json.Name;
    this.logo        = json.Logo;
    this.header      = json.Header;
    this.footer      = json.Footer;
    this.description = json.Description;
    this.features    = json.Features;
    this.table       = new Table(json.Table);

  }
}

export class Table {
  header: string[];
  content: string[][];

  constructor(json) {
    if (!json) {
      return;
    }

    this.header  = json.Header;
    this.content = json.Content;
  }

}

export class Competitor {
  name: string;
  gas: string;
  image: string;
  description: string;
  folio: string;

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.name        = json.Name;
    this.gas         = json.Gas;
    this.folio       = json.Equipment;
    this.description = json.Description;
    this.image       = json.Image;
  }
}

export class Faq {
  question: string;
  answer: string;

  constructor(json ?: any) {
    if (!json) {
      return;
    }

    this.question = json.Question;
    this.answer   = json.Answer;
  }
}
