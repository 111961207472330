<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over">
    <mat-toolbar>
      <mat-toolbar-row>
        <a [routerLink]="['/home']" routerLinkActive="is-active" title="{{ 'HOME' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3">home</mat-icon>
          <span class="align-middle">{{ 'HOME' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <a [routerLink]="['/selector']" routerLinkActive="is-active" title="{{ 'SOLUTION_SELECTOR' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3" svgIcon="mat_selector"></mat-icon>
          <span class="align-middle">{{ 'SOLUTION_SELECTOR' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <a [routerLink]="['/portfolio']" routerLinkActive="is-active" title="{{ 'PORTFOLIO' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3" svgIcon="mat_portfolio"></mat-icon>
          <span class="align-middle">{{ 'PORTFOLIO' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="appService.estimation.length !== 0">
        <a [routerLink]="['/estimation']" routerLinkActive="is-active"
           title="{{ 'CONSUMPTION_ESTIMATION' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3" svgIcon="mat_estimation"></mat-icon>
          <span class="align-middle">{{ 'CONSUMPTION_ESTIMATION' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="appService.questionnaire.length !== 0">
        <a [routerLink]="['/project']" routerLinkActive="is-active" title="{{ 'PROJECT_INITIATION' | translate }}"
           (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3" svgIcon="mat_cogs"></mat-icon>
          <span class="align-middle">{{ 'PROJECT_INITIATION' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <a [routerLink]="['/faq']" routerLinkActive="is-active" title="{{ 'FAQ' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3" svgIcon="mat_faq"></mat-icon>
          <span class="align-middle">{{ 'FAQ' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="!(appService.locked$ | async)">
        <a (click)="sidenav.close()" [routerLink]="['/competitors']" routerLinkActive="is-active"
           title="{{ 'COMPETITOR' | translate }}">
          <mat-icon class="align-middle mr-3" svgIcon="mat_portfolio"></mat-icon>
          <span class="align-middle">{{ 'COMPETITOR' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row class="divider">
        <mat-divider></mat-divider>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <a [routerLink]="['/synchronization']" routerLinkActive="is-active" title="{{ 'SYNCHRONIZATION' | translate }}" (click)="sidenav.close()">
          <mat-icon *ngIf="syncService.update$ | async" svgIcon="mat_synchronization_pending"
                    class="align-middle mr-3"></mat-icon>
          <mat-icon *ngIf="!(syncService.update$ | async)" svgIcon="mat_synchronization"
                    class="align-middle mr-3"></mat-icon>
          <span class="align-middle">{{ 'SYNCHRONIZATION' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <a [routerLink]="['/settings']" routerLinkActive="is-active" title="{{ 'SETTINGS' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3">tune</mat-icon>
          <span class="align-middle">{{ 'SETTINGS' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <a [routerLink]="['/information']" routerLinkActive="is-active" title="{{ 'INFORMATIONS' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3">info</mat-icon>
          <span class="align-middle">{{ 'INFORMATIONS' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="(authService.authenticated$ | async) === UserType.Admin">
        <a [routerLink]="['/administration']" routerLinkActive="is-active" [class.disabled-item]="!(syncService.connected$ | async)"
           title="{{ 'ADMINISTRATION' | translate }}" (click)="sidenav.close()">
          <mat-icon class="align-middle mr-3">business</mat-icon>
          <span class="align-middle">{{ 'ADMINISTRATION' | translate }}</span>
        </a>
      </mat-toolbar-row>
      <mat-toolbar-row class="divider">
        <mat-divider></mat-divider>
      </mat-toolbar-row>
      <mat-toolbar-row>
        <a title="{{ 'SIGN_OUT' | translate }}" (click)="logout()">
          <mat-icon class="align-middle mr-3">power_settings_new</mat-icon>
          <span class="align-middle">{{ 'SIGN_OUT' | translate }}</span>
        </a>
      </mat-toolbar-row>
    </mat-toolbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="bg-primary text-white">
      <div>
        <button mat-icon-button (click)="sidenav.toggle()" [disabled]="false">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="toolbar-title text-center">
        <div class="d-inline-block mx-auto">
          {{ appService.title$ | delay | async | translate }}
        </div>
      </div>
      <div class="toolbar-actions">
        <div class="lock">
          <button *ngIf="appService.locked$ | async" mat-icon-button (click)="appService.unlock()">
            <mat-icon>lock</mat-icon>
          </button>
          <button *ngIf="!(appService.locked$ | async)" mat-icon-button (click)="appService.locked$.next(true)">
            <mat-icon>lock_open</mat-icon>
          </button>
        </div>
        <img class="logo ml-4" alt="logo" src="../../assets/img/air-liquide-cube-white.svg"/>
      </div>
    </mat-toolbar>

    <mat-progress-bar color="success" mode="indeterminate" *ngIf="loading"></mat-progress-bar>

    <div class="app-content-wrapper">
      <div id="app-content" class="app-content" #content>
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="nav-container">
      <button *ngIf="back" mat-fab color="primary" class="back back-lg" (click)="back()"><mat-icon>arrow_back</mat-icon></button>
      <button *ngIf="back" mat-fab color="primary" class="back back-sm" (click)="alternateBack()"><mat-icon>arrow_back</mat-icon></button>
      <button *ngIf="next" mat-fab color="primary" class="next" (click)="next()"><mat-icon>arrow_forward</mat-icon></button>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
